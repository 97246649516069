import { MenuDish } from '../menu-dish/menu-dish.model'
import { MenuPrintTemplate } from '../menu-print-template/menu-print-template.model'
import { Menu } from '../menu/menu.model'
import { MenuSection } from '../menu-section/menu-section.model'
import { PrintPage } from './print-page.model'
import { PrintPageStringElement } from './print-page-elements/string-element.model'
import { MenuPrintTemplateMenuElement } from '../menu-print-template/elements/menu-element.model'
import { MenuPrintTemplateMenuDateElement } from '../menu-print-template/elements/menu-date-element.model'
import { MenuPrintTemplateMenuTitleElement } from '../menu-print-template/elements/menu-title-element.model'
import { MenuPrintTemplateCustomTextElement } from '../menu-print-template/elements/custom-text-element.model'
import { MenuSectionTemplate } from '../menu-templates/menu-section-template.model'

export class PrintDocument {
  public pages: PrintPage[] = []
  public onePageScaling: number = 1

  constructor(public documentWrapper: HTMLElement, public menuPrintTemplate: MenuPrintTemplate, public menu: Menu, public selectedMenuSectionTemplates?: MenuSectionTemplate[], public selectedMenuDishes?: MenuDish[]) {
    this.renderDocument()
  }

  public get firstPageElement(): HTMLElement {
    return this.documentWrapper?.firstChild.nodeName == 'DIV' ? (this.documentWrapper?.firstChild?.firstChild as HTMLElement) : undefined
  }
  public get bodyElement(): HTMLElement {
    return this.firstPageElement.children[1] as HTMLElement
  }
  public get width(): number {
    return this.firstPageElement?.clientWidth || 0
  }
  public get height(): number {
    return this.width * this.aspectRatio
  }
  public get aspectRatio(): number {
    return this.menuPrintTemplate?.template?.pageFormat?.orientation?.value == 'portrait' ? 297 / 210 : 210 / 297
  }

  public get sizeFactor(): number {
    return this.width / 792
  }
  public get currentPage(): PrintPage {
    if (this.pages.length) return this.pages[this.pages.length - 1]
    else return
  }

  public renderDocument() {
    this.onePageScaling = 1
    this.pages = []
    this._addPage()
    this._addBodyElements(this.generatePrintPageElementsFromTemplate(this.menuPrintTemplate.template.body.elements))
  }

  public generatePrintPageElementsFromTemplate(elements: (MenuPrintTemplateMenuElement | MenuPrintTemplateMenuTitleElement | MenuPrintTemplateMenuDateElement | MenuPrintTemplateCustomTextElement)[]): PrintPageStringElement[][][] {
    return elements
      .map((elementTemplate) => {
        if (elementTemplate.type == 'menu') return this.generatePrintPageElementsFromMenu(elementTemplate as MenuPrintTemplateMenuElement)
        else if (elementTemplate.type == 'menuTitle') return [[[new PrintPageStringElement(elementTemplate, this, undefined, this.menu.menuTemplate)]]]
        else if (elementTemplate.type == 'menuDate') return [[[new PrintPageStringElement(elementTemplate, this, undefined, this.menu.date)]]]
        else if (elementTemplate.type == 'customString') return [[[new PrintPageStringElement(elementTemplate, this, undefined, (elementTemplate as MenuPrintTemplateCustomTextElement).customContent)]]]
        else return [[[new PrintPageStringElement(elementTemplate, this)]]]
      })
      .flat()
  }

  public generatePrintPageElementsFromMenu(elementTemplate: MenuPrintTemplateMenuElement): PrintPageStringElement[][][] {
    let elementGroups = []
    this.menu.menu_sections_sorted
      .filter((menuSection: MenuSection) => this.selectedMenuSectionTemplates.find((sectionTemplate) => sectionTemplate.id == menuSection.section_template.id))
      .forEach((menuSection: MenuSection) => {
        const selectedMenuDishes = menuSection.menu_dishes_sorted.filter((menuDish: MenuDish) => this.selectedMenuDishes.find((selectedMenuDish) => selectedMenuDish.id == menuDish.id))
        const sectionElement = new PrintPageStringElement(elementTemplate, this, 'sectionName', menuSection.section_template)
        if (selectedMenuDishes.length) {
          if (this.menuPrintTemplate.template.pageFormat.pageSplit.value == 'dish' && !sectionElement.elementTemplate.format.hidden.value && sectionElement.elementString) elementGroups.push([[sectionElement]])
          let dishes = []
          selectedMenuDishes.forEach((menuDish: MenuDish) => {
            let dish = [
              new PrintPageStringElement(elementTemplate, this, 'dishName', menuDish.recipe),
              new PrintPageStringElement(elementTemplate, this, 'dishDescription', menuDish.recipe),
              new PrintPageStringElement(elementTemplate, this, 'dishIngredients', menuDish.recipe),
              new PrintPageStringElement(elementTemplate, this, 'dishAllergens', menuDish.recipe),
              new PrintPageStringElement(elementTemplate, this, 'dishTags', menuDish.recipe),
              new PrintPageStringElement(elementTemplate, this, 'dishCO2', menuDish.recipe)
            ].filter((element) => !element.elementTemplate.format.hidden.value && element.elementString)
            if (dish.length) {
              if (this.menuPrintTemplate.template.pageFormat.pageSplit.value == 'dish') elementGroups.push([dish])
              else dishes.push(dish)
            }
          })
          if (this.menuPrintTemplate.template.pageFormat.pageSplit.value != 'dish' && dishes.length) elementGroups.push([[sectionElement].filter((element) => !element.elementTemplate.format.hidden.value && element.elementString), ...dishes])
        }
      })
    return elementGroups
  }

  private _addPage() {
    this.pages.push(new PrintPage(this))
  }

  private _addBodyElements(printPageElementSections: PrintPageStringElement[][][]): void {
    const interval = setInterval(() => {
      if (this.firstPageElement) {
        clearInterval(interval)
        if (this.menuPrintTemplate.template.pageFormat.pageSplit.value == 'onePage') this._addBodyToOnePage(printPageElementSections.flat().flat())
        else this._addBodyToMultiplePages(printPageElementSections)
      }
    }, 100)
  }

  private _addBodyToMultiplePages(printPageElementSections: PrintPageStringElement[][][]): void {
    printPageElementSections.forEach((printPageElementSection: PrintPageStringElement[][], sectionIndex: number) => {
      const printPageElementSectionDOMSize = printPageElementSection
        .flat()
        .flat()
        .reduce((acc, printPageElement) => acc + printPageElement.componentDOMSize, 0)
      if (
        printPageElementSectionDOMSize + this.currentPage.contentHeight > this.currentPage.availableContentHeight ||
        (printPageElementSection.flat().flat()[0].menuItemIdentifier && sectionIndex > 0 && this.menuPrintTemplate.template.pageFormat.pageSplit.value == 'sectionOnePage')
      )
        this._addPage()

      printPageElementSection.forEach((printPageElementGroup: PrintPageStringElement[]) => {
        const printPageElementGroupDOMSize = printPageElementGroup.flat().reduce((acc, printPageElement) => acc + printPageElement.componentDOMSize, 0)
        if (printPageElementGroupDOMSize + this.currentPage.contentHeight > this.currentPage.availableContentHeight) this._addPage()
        printPageElementGroup.forEach((printPageElement: PrintPageStringElement) => {
          this.currentPage.bodyElements.push(printPageElement)
          this.currentPage.contentHeight += printPageElement.componentDOMSize
        })
      })
    })
  }

  private _addBodyToOnePage(printPageElements: PrintPageStringElement[]): void {
    while (printPageElements.reduce((acc, printPageElement) => acc + printPageElement.componentDOMSize, 0) > this.currentPage.availableContentHeight) {
      this.onePageScaling -= 0.01
      if (this.onePageScaling < 0.25) break
    }
    if (printPageElements.reduce((acc, printPageElement) => acc + printPageElement.componentDOMSize, 0) <= this.currentPage.availableContentHeight) {
      printPageElements.forEach((printPageElement) => {
        this.currentPage.bodyElements.push(printPageElement)
        this.currentPage.contentHeight += printPageElement.componentDOMSize
      })
    }
  }
}
