import { FormControl, FormBuilder } from '@angular/forms'
import { map, tap } from 'rxjs/operators'
import { Observable } from 'rxjs'
import { FoodopLibModule } from '../../foodop-lib.module'
import { IMenuPrintTemplate, IMenuPrintTemplateElement } from '../../global.models'
import { MenuPrintTemplatePageFormat } from './formats/page-format.model'
import { MenuPrintTemplatesService } from './menu-print-template.service'
import { MenuPrintTemplateImageElement } from './elements/image-element.model'
import { MenuPrintTemplateMenuElement } from './elements/menu-element.model'
import { MenuPrintTemplateMenuDateElement } from './elements/menu-date-element.model'
import { MenuPrintTemplateCustomTextElement } from './elements/custom-text-element.model'
import { MenuPrintTemplateDishIngredientsElement } from './elements/dish-ingredients-element.model'
import { MenuPrintTemplateDishTagsElement } from './elements/dish-tags-element.model'
import { MenuPrintTemplateMenuTitleElement } from './elements/menu-title-element.model'
import { MenuPrintTemplateDishAllergensElement } from './elements/dish-allergens-element.model'
import { MenuPrintTemplateDishCO2Element } from './elements/dish-co2-element.model'

export class MenuPrintTemplate {
  public id: string
  public name: FormControl
  public language: FormControl
  public template: {
    header: {
      elements: (MenuPrintTemplateMenuElement | MenuPrintTemplateMenuTitleElement | MenuPrintTemplateMenuDateElement | MenuPrintTemplateCustomTextElement)[]
      format: MenuPrintTemplatePageFormat
    }
    body: {
      elements: (MenuPrintTemplateMenuElement | MenuPrintTemplateMenuTitleElement | MenuPrintTemplateMenuDateElement | MenuPrintTemplateCustomTextElement)[]
      format: MenuPrintTemplatePageFormat
    }
    footer: {
      elements: (MenuPrintTemplateMenuElement | MenuPrintTemplateMenuTitleElement | MenuPrintTemplateMenuDateElement | MenuPrintTemplateCustomTextElement)[]
      format: MenuPrintTemplatePageFormat
    }
    imageElements: MenuPrintTemplateImageElement[]
    pageFormat: MenuPrintTemplatePageFormat
  }

  public selectedItems: FormControl = new FormControl([])
  public saving: boolean = false

  private _fb: FormBuilder
  private _savedTemplate: string
  private _menuPrintTemplatesService: MenuPrintTemplatesService

  constructor(menuPrintTemplate?: IMenuPrintTemplate) {
    this._menuPrintTemplatesService = FoodopLibModule.injector.get(MenuPrintTemplatesService)
    this._fb = FoodopLibModule.injector.get(FormBuilder)

    this.id = menuPrintTemplate?.id
    this.name = this._fb.control(menuPrintTemplate?.name || 'Printskabelon')
    this.language = this._fb.control(menuPrintTemplate?.language || 'da')
    this.template = {
      header: {
        elements: (menuPrintTemplate?.template?.header?.elements || []).map((element) => this._createElementModel(element)),
        format: new MenuPrintTemplatePageFormat(menuPrintTemplate?.template?.header?.format || { marginTop: 25.4, marginLeft: 25.4, marginRight: 25.4 })
      },
      body: {
        elements: (menuPrintTemplate?.template?.body?.elements || []).map((element) => this._createElementModel(element)),
        format: new MenuPrintTemplatePageFormat(menuPrintTemplate?.template?.body?.format || { marginLeft: 25.4, marginRight: 25.4 })
      },
      footer: {
        elements: (menuPrintTemplate?.template?.footer?.elements || []).map((element) => this._createElementModel(element)),
        format: new MenuPrintTemplatePageFormat(menuPrintTemplate?.template?.footer?.format || { marginBottom: 25.4, marginLeft: 25.4, marginRight: 25.4 })
      },
      imageElements: (menuPrintTemplate?.template?.imageElements || []).map((element) => new MenuPrintTemplateImageElement(element)),
      pageFormat: new MenuPrintTemplatePageFormat(menuPrintTemplate?.template?.pageFormat)
    }

    this._savedTemplate = JSON.stringify(this.asDict)
  }

  public get allElements(): any[] {
    return [
      ...this.template.header.elements.flatMap((element) => (element.type == 'menu' ? Object.values((element as MenuPrintTemplateMenuElement).menuElements) : (element as any))),
      ...this.template.body.elements.flatMap((element) => (element.type == 'menu' ? Object.values((element as MenuPrintTemplateMenuElement).menuElements) : (element as any))),
      ...this.template.footer.elements.flatMap((element) => (element.type == 'menu' ? Object.values((element as MenuPrintTemplateMenuElement).menuElements) : (element as any))),
      ...this.template.imageElements
    ]
  }

  public get customPrintElements(): MenuPrintTemplateCustomTextElement[] {
    return this.allElements.filter((element) => element.type == 'customString') as MenuPrintTemplateCustomTextElement[]
  }

  public get menuTitleAdded(): MenuPrintTemplateMenuTitleElement {
    return this.allElements.find((element) => element.type == 'menuTitle') as MenuPrintTemplateMenuTitleElement
  }

  public get menuDateAdded(): MenuPrintTemplateMenuDateElement {
    return this.allElements.find((element) => element.type == 'menuDate') as MenuPrintTemplateMenuDateElement
  }

  public get dateFormatSelected(): MenuPrintTemplateMenuDateElement {
    return this.selectedItems.value.find((element) => element.type == 'menuDate')
  }

  public get dishCO2Selected(): MenuPrintTemplateDishCO2Element {
    return this.selectedItems.value.find((element) => element.type == 'dishCO2')
  }

  public get dishIngredientsSelected(): MenuPrintTemplateDishIngredientsElement {
    return this.selectedItems.value.find((element) => element.type == 'dishIngredients')
  }

  public get dishAllergensSelected(): MenuPrintTemplateDishAllergensElement {
    return this.selectedItems.value.find((element) => element.type == 'dishAllergens')
  }

  public get dishTagsSelected(): MenuPrintTemplateDishTagsElement {
    return this.selectedItems.value.find((element) => element.type == 'dishTags')
  }

  public get customStringSelected(): MenuPrintTemplateCustomTextElement {
    return this.selectedItems.value.find((element) => element.type == 'customString')
  }

  public get imageFormatSelected(): MenuPrintTemplateImageElement {
    return this.selectedItems.value.find((element) => element.type == 'image')
  }

  public get menuElementsSelected(): boolean {
    return this.selectedItems.value.find((element) => ['sectionName', 'dishName', 'dishDescription', 'dishIngredients', 'dishAllergens', 'dishTags', 'dishCO2'].includes(element.type)) != undefined
  }

  public get asDict(): IMenuPrintTemplate {
    return {
      id: this.id,
      name: this.name.value,
      language: this.language.value,
      template: this._templateAsDict
    }
  }

  public get changed(): boolean {
    return this._savedTemplate != JSON.stringify(this.asDict)
  }

  public get isNew(): boolean {
    return this.id == undefined
  }

  private get _templateAsDict(): any {
    return {
      header: {
        elements: this.template.header.elements.map((element) => element.asDict),
        format: this.template.header.format.asDict
      },
      body: {
        elements: this.template.body.elements.map((element) => element.asDict),
        format: this.template.body.format.asDict
      },
      footer: {
        elements: this.template.footer.elements.map((element) => element.asDict),
        format: this.template.footer.format.asDict
      },
      imageElements: this.template.imageElements.map((element) => element.asDict),
      pageFormat: this.template.pageFormat.asDict
    }
  }

  public addElement(element: IMenuPrintTemplateElement, section?: string, index?: number): MenuPrintTemplateCustomTextElement | MenuPrintTemplateMenuTitleElement | MenuPrintTemplateMenuDateElement | MenuPrintTemplateMenuElement {
    const newElement = this._createElementModel(element)
    this.template[section].elements.splice(index != undefined ? index : this.template[section].elements.length, 0, newElement)
    return newElement
  }

  public addImageElement(element: IMenuPrintTemplateElement): void {
    this.template.imageElements.push(new MenuPrintTemplateImageElement(element))
  }

  public removeElement(element: MenuPrintTemplateMenuTitleElement | MenuPrintTemplateMenuDateElement | MenuPrintTemplateCustomTextElement | MenuPrintTemplateImageElement): void {
    this.template.header.elements = this.template.header.elements.filter((el) => el.identifier != element.identifier)
    this.template.body.elements = this.template.body.elements.filter((el) => el.identifier != element.identifier)
    this.template.footer.elements = this.template.footer.elements.filter((el) => el.identifier != element.identifier)
    this.template.imageElements = this.template.imageElements.filter((el) => el.identifier != element.identifier)
  }

  public restore(): void {
    this.patchValues(JSON.parse(this._savedTemplate))
  }

  public save(): Observable<MenuPrintTemplate> {
    this.saving = true
    return this._menuPrintTemplatesService.saveMenuPrintTemplate(this).pipe(
      tap((menuPrintTemplate) => {
        this.id = menuPrintTemplate['id']
        this._savedTemplate = JSON.stringify(this.asDict)
        this.saving = false
      }),
      map(() => {
        return this
      })
    )
  }

  public patchValues(menuPrintTemplate: IMenuPrintTemplate): void {
    if (!this.id) this.id = menuPrintTemplate.id
    if (menuPrintTemplate.name) this.name.setValue(menuPrintTemplate.name, { emitEvent: false })
    if (menuPrintTemplate.language) this.language.setValue(menuPrintTemplate.language, { emitEvent: false })
    if (menuPrintTemplate.template) {
      this.template = {
        header: {
          elements: menuPrintTemplate?.template?.header?.elements?.map((element) => this._createElementModel(element)),
          format: new MenuPrintTemplatePageFormat(menuPrintTemplate?.template?.header?.format)
        },
        body: {
          elements: menuPrintTemplate?.template?.body?.elements?.map((element) => this._createElementModel(element)),
          format: new MenuPrintTemplatePageFormat(menuPrintTemplate?.template?.body?.format)
        },
        footer: {
          elements: menuPrintTemplate?.template?.footer?.elements?.map((element) => this._createElementModel(element)),
          format: new MenuPrintTemplatePageFormat(menuPrintTemplate?.template?.footer?.format)
        },
        imageElements: menuPrintTemplate?.template?.imageElements?.map((element) => new MenuPrintTemplateImageElement(element)),
        pageFormat: new MenuPrintTemplatePageFormat(menuPrintTemplate?.template?.pageFormat)
      }
    }
  }

  private _createElementModel(element): MenuPrintTemplateCustomTextElement | MenuPrintTemplateMenuTitleElement | MenuPrintTemplateMenuDateElement | MenuPrintTemplateMenuElement {
    switch (element.type) {
      case 'customString':
        return new MenuPrintTemplateCustomTextElement(element)
      case 'menuTitle':
        return new MenuPrintTemplateMenuTitleElement(element)
      case 'menuDate':
        return new MenuPrintTemplateMenuDateElement(element)
      case 'menu':
        return new MenuPrintTemplateMenuElement(element)
    }
  }
}
