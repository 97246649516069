import { FormControl, Validators } from '@angular/forms'
import { FoodopLibModule } from '../../foodop-lib.module'
import { MenuSection } from '../menu-section/menu-section.model'
import { Names } from '../names/names.model'
import { Observable } from 'rxjs'
import { MenuTemplatesService } from './menu-templates.service'
import { map, tap } from 'rxjs/operators'
import { IMenuTemplate } from '../../global.models'
import { MenuSectionTemplate } from './menu-section-template.model'
import { TrackingTemplate } from '../tracking-templates/tracking-template.model'
import { TrackingTemplatesService } from '../tracking-templates/tracking-templates.service'

export class MenuTemplate {
  public id: string
  public names: Names
  public index: FormControl
  public type: FormControl
  public active: FormControl
  public weekdays: FormControl

  public kiloPrice: FormControl

  public menuSectionTemplates: MenuSectionTemplate[]
  public menuPrintTemplateId: FormControl
  public subsidiaryId: string

  public saving = false

  private _savedTemplate: any
  private _menuTemplatesService: MenuTemplatesService
  private _trackingTemplatesService: TrackingTemplatesService

  constructor(public menuTemplate?: IMenuTemplate, language?: string) {
    this._menuTemplatesService = FoodopLibModule.injector.get(MenuTemplatesService)
    this._trackingTemplatesService = FoodopLibModule.injector.get(TrackingTemplatesService)

    this.id = menuTemplate?.id
    this.names = new Names(menuTemplate?.names)
    this.index = new FormControl({ value: menuTemplate?.index || 0, disabled: false }, [Validators.required])
    this.type = new FormControl({ value: menuTemplate?.type, disabled: false }, [Validators.required])
    this.active = new FormControl(menuTemplate?.active != undefined ? menuTemplate?.active : true)
    this.weekdays = new FormControl({ value: this._parseWeekdays(menuTemplate?.weekdays), disabled: false }, [Validators.required])

    this.kiloPrice = new FormControl({ value: menuTemplate?.kilo_price || 20, disabled: false }, [Validators.required])

    this.menuSectionTemplates = (menuTemplate?.menu_section_templates || []).sort((a, b) => (a.index < b.index ? 1 : -1)).map((menu_section_template) => new MenuSectionTemplate(menu_section_template))
    this.menuPrintTemplateId = new FormControl({ value: menuTemplate?.menu_print_template_id, disabled: false }, [Validators.required])
    this.subsidiaryId = menuTemplate?.subsidiary_id

    this._savedTemplate = JSON.stringify(this.asDict)
  }

  public get isNew(): boolean {
    return this.id == undefined
  }

  public get changed(): boolean {
    return this._savedTemplate != JSON.stringify(this.asDict)
  }

  public get valid(): boolean {
    return Object.keys(this).find((key) => this[key]?.invalid == true) == undefined && this.names.valid && this.areMenuSectionTemplatesValid
  }

  public get areMenuSectionTemplatesValid(): boolean {
    return this.menuSectionTemplates.find((menuSectionTemplate) => menuSectionTemplate.valid == false) == undefined
  }

  public get menuSectionTemplatesSorted(): MenuSectionTemplate[] {
    return this.menuSectionTemplates.sort((a, b) => (a.index.value > b.index.value ? 1 : -1))
  }

  public get activeMenuSectionTemplatesSorted(): MenuSectionTemplate[] {
    return this.menuSectionTemplates.filter((menuSectionTemplate) => menuSectionTemplate.active.value).sort((a, b) => (a.index.value > b.index.value ? 1 : -1))
  }

  public get trackingTemplates(): any[] {
    return this._trackingTemplatesService.trackingTemplatesForMenuTemplateId(this.id)
  }

  public get activeTrackingTemplates(): TrackingTemplate[] {
    return this._trackingTemplatesService.trackingTemplatesForMenuTemplateId(this.id, true)
  }

  public get selectedWeekdays(): number[] {
    return this.weekdays.value.filter((weekday) => weekday != 'all')
  }

  public get asDict(): IMenuTemplate {
    return {
      id: this.id,
      names: this.names.as_dict,
      index: this.index.value,
      type: this.type.value,
      active: this.active.value,
      weekdays: this.selectedWeekdays,
      kilo_price: this.kiloPrice.value,
      menu_section_templates: this.menuSectionTemplatesSorted.map((menuSectionTemplate) => menuSectionTemplate.asDict),
      menu_print_template_id: this.menuPrintTemplateId.value,
      subsidiary_id: this.subsidiaryId
    }
  }

  public get namesDict(): IMenuTemplate {
    return {
      id: this.id,
      active: this.active.value,
      names: this.names.as_dict
    }
  }
  private get _indexDict(): IMenuTemplate {
    return {
      id: this.id,
      active: this.active.value,
      index: this.index.value
    }
  }

  public menuSectionTemplateWithId(menuSectionTemplateId: string): MenuSectionTemplate {
    return this.menuSectionTemplates.find((menuSectionTemplate) => menuSectionTemplate.id == menuSectionTemplateId)
  }

  public isTranslated(language: string) {
    return this.names[language].value ? true : false
  }

  public getTemplateForSection(menuSection: MenuSection): MenuSectionTemplate {
    return this.menuSectionTemplates.find((menuSectionTemplate) => menuSectionTemplate.id == menuSection.menuSectionTemplateId)
  }

  public load(): Observable<MenuTemplate> {
    return this._menuTemplatesService.getMenuTemplateByID(this.id).pipe(
      tap((menuTemplate) => {
        this.patchValues(menuTemplate)
        this._savedTemplate = JSON.stringify(this.asDict)
      }),
      map(() => {
        return this
      })
    )
  }

  public save(): Observable<MenuTemplate> {
    this.saving = true
    return this._menuTemplatesService.saveMenuTemplate(this).pipe(
      tap((menuTemplate) => {
        this.patchValues(menuTemplate)
        this._savedTemplate = JSON.stringify(this.asDict)
        this.saving = false
      }),
      map(() => {
        return this
      })
    )
  }

  public saveNames(): Observable<MenuTemplate> {
    this.saving = true
    return this._menuTemplatesService.saveMenuTemplateNames(this.namesDict).pipe(
      tap((menuTemplate) => {
        this.patchValues(menuTemplate)
        this._savedTemplate = JSON.stringify(this.asDict)
        this.saving = false
      }),
      map(() => {
        return this
      })
    )
  }

  public saveIndex(): Observable<MenuTemplate> {
    this.saving = true
    return this._menuTemplatesService.saveMenuTemplateIndex(this._indexDict).pipe(
      tap((menuTemplate) => {
        this.patchValues(menuTemplate)
        this._savedTemplate = JSON.stringify(this.asDict)
        this.saving = false
      }),
      map(() => {
        return this
      })
    )
  }

  public patchValues(menuTemplate?: IMenuTemplate): void {
    if (menuTemplate?.id) this.id = menuTemplate?.id
    if (menuTemplate?.names) this.names.patchValue(menuTemplate.names)
    if (menuTemplate?.index != undefined) this.index.setValue(menuTemplate.index)
    if (menuTemplate?.type) this.type.setValue(menuTemplate.type)
    if (menuTemplate?.active != undefined) this.active.setValue(menuTemplate.active)
    if (menuTemplate?.weekdays) this.weekdays.setValue(this._parseWeekdays(menuTemplate.weekdays))

    if (menuTemplate?.menu_section_templates) this.menuSectionTemplates = menuTemplate.menu_section_templates.sort((a, b) => (a.index < b.index ? 1 : -1)).map((menu_section_template) => new MenuSectionTemplate(menu_section_template))
    if (menuTemplate?.menu_print_template_id) this.menuPrintTemplateId.setValue(menuTemplate?.menu_print_template_id)
    if (menuTemplate?.subsidiary_id) this.subsidiaryId = menuTemplate?.subsidiary_id

    this._savedTemplate = JSON.stringify(this.asDict)
  }

  private _parseWeekdays(weekdays?: number[]): any[] {
    if (!weekdays) return [0, 1, 2, 3, 4]
    if (weekdays.length == 7) return ['all'].concat(weekdays as any[])
    return weekdays
  }
}
