import { Inject, Injectable, LOCALE_ID } from '@angular/core'
import { RestApiService } from '../../services/rest-api.service'
import { Observable } from 'rxjs'
import { tap } from 'rxjs/operators'
import { ProcurementProduct } from './procurement-product.model'
import { ProcurementDataFile } from './procurement-data-file.model'
import { IProcurementDataFile, IProcurementProduct } from '../../global.models'
import { CacheMapService } from '../../services/caching/cache-map.service'

@Injectable({
  providedIn: 'root'
})
export class ProcurementService {
  files: ProcurementDataFile[] = []
  files_count: number = 0

  files_loading: boolean = false
  files_loaded: boolean = false

  products: ProcurementProduct[] = []
  all_product_count: number = 0
  product_mapping_succeeded_count: number = 0
  product_mapping_ongoing_count: number = 0
  product_mapping_failed_count: number = 0
  product_non_food_count: number = 0
  product_excluded_from_organic_report_count: number = 0

  products_loading: boolean = false
  products_loaded: boolean = false

  constructor(@Inject(LOCALE_ID) public language: string, private restApiService: RestApiService, private cacheMapService: CacheMapService) {}

  getProducts(params, smooth_reload?: boolean, pageSize?: number): Observable<any[]> {
    if (!smooth_reload) this.products_loading = true
    return this.restApiService.getProcurementData(params).pipe(
      tap((response) => {
        if (response && response[0]['products']) this._updateProducts(response[0]['products'], smooth_reload, pageSize)
        this.all_product_count = response[0]['count']
        this.product_mapping_failed_count = response[0]['mapping_failed_count']
        this.product_mapping_ongoing_count = response[0]['mapping_ongoing_count']
        this.product_mapping_succeeded_count = response[0]['mapping_succeeded_count']
        this.product_non_food_count = response[0]['non_food_count']
        this.product_excluded_from_organic_report_count = response[0]['excluded_from_organic_report_count']
        this.products_loading = false
        this.products_loaded = true
        this.cacheMapService.deleteCacheEntryForURL('procurement')
      })
    )
  }

  _updateProducts(updated_products: IProcurementProduct[], smooth_reload: boolean, pageSize: number): void {
    if (smooth_reload) {
      this._patch_unchanged_products(updated_products)
      this.products = this.products.concat(
        updated_products
          .filter((updated_product) => !this.products.map((product) => product.product_id).includes(updated_product.product_id))
          .slice(0, pageSize - this.products.length)
          .map((updated_product) => new ProcurementProduct(updated_product, null, this.language))
      )
    } else this.products = updated_products.map((product) => new ProcurementProduct(product, null, this.language))
  }

  getFiles(params): Observable<any[]> {
    this.files_loading = true
    return this.restApiService.getProcurementData(params).pipe(
      tap((response) => {
        if (response) {
          this.files = response[0]['files'].map((file) => new ProcurementDataFile(file))
          this.files_count = response[0]['count']
        }
        this.files_loading = false
        this.files_loaded = true
      })
    )
  }

  matchColumns(headers: string[]): Observable<any> {
    let params = {
      language: this.language
    }
    return this.restApiService.matchProcurementFileColumns(headers, params)
  }

  uploadDataFile(file: IProcurementDataFile, params: object): Observable<IProcurementDataFile> {
    return this.restApiService.createProcurementData(file, params).pipe(tap(() => this.cacheMapService.deleteCacheEntryForURL('procurement')))
  }

  updateProduct(product: IProcurementProduct, params: object): Observable<IProcurementProduct[]> {
    return this.restApiService.updateProcurementData(product, params).pipe(
      tap((updated_products) => {
        if (updated_products) this._patch_unchanged_products(updated_products)
        this.cacheMapService.deleteCacheEntryForURL('procurement')
      })
    )
  }

  _patch_unchanged_products(updated_products: IProcurementProduct[]): void {
    updated_products.forEach((updated_product) => {
      let updated_shown_product = this.products.find((product) => product.product_id == updated_product.product_id && !product.changed)
      if (updated_shown_product) {
        updated_shown_product.patchValues(updated_product)
      }
    })
  }

  deleteFile(file_to_delete: ProcurementDataFile, params: object): Observable<any> {
    if (file_to_delete.file_id) params['file_id'] = file_to_delete.file_id
    return this.restApiService.deleteProcurementData(params).pipe(
      tap(() => {
        this.files = this.files.filter((file) => file.file_id != file_to_delete.file_id)
        this.products = this.products.filter((product) => product.file_id != file_to_delete.file_id)
        this.files_count -= 1
        this.all_product_count -= file_to_delete.products.length
        this.cacheMapService.deleteCacheEntryForURL('procurement')
      })
    )
  }
  deleteProduct(product_to_delete: ProcurementProduct, params: object): Observable<any> {
    if (product_to_delete.product_id) params['product_id'] = product_to_delete.product_id
    return this.restApiService.deleteProcurementData(params).pipe(
      tap(() => {
        this.products = this.products.filter((product) => product.product_id != product_to_delete.product_id)
        this.cacheMapService.deleteCacheEntryForURL('procurement')
      })
    )
  }
}
