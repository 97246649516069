import { Inject, Injectable, LOCALE_ID } from '@angular/core'
import { Observable, of } from 'rxjs'
import { map, tap, flatMap, mergeAll, concatAll, toArray } from 'rxjs/operators'
import { RestApiService } from '../../services/rest-api.service'
import { RawIngredient } from './raw-ingredient.model'
import { IRawIngredient } from '../../global.models'

@Injectable({
  providedIn: 'root'
})
export class RawIngredientsService {
  raw_ingredients = []

  constructor(@Inject(LOCALE_ID) public language: string, private restApiService: RestApiService) {}

  searchIngredients(name: string, offset: number): Observable<IRawIngredient[]> {
    if (!name || name.length <= 1) {
      console.log('Search term too short: ' + name)
      return of([])
    } else {
      console.log('Querying DB for search term: ' + name)
      const params = {
        query: encodeURIComponent(name),
        limit: 10,
        offset: offset,
        language: this.language,
        fields: ['names']
      }
      return this.restApiService.getRawIngredients(params).pipe(
        map((ingredients) => {
          return ingredients.map((ingredient) => {
            const generic_ingredient = ingredient['ingredients'].find((_ingredient) => _ingredient.ingredient_type == 'generic')
            const custom_ingredients = ingredient['ingredients'].filter((_ingredient) => _ingredient.ingredient_type == 'custom')
            if (generic_ingredient && custom_ingredients) return [...custom_ingredients, generic_ingredient]
            else if (generic_ingredient && !custom_ingredients) return [generic_ingredient]
            else if (!generic_ingredient && custom_ingredients) return [...custom_ingredients]
          })
        }),
        map((ingredients) => {
          return ingredients.flat()
        })
      )
    }
  }

  loadUsersCustomIngredientsWithName(name: string): Observable<IRawIngredient[]> {
    const params = {
      query: encodeURIComponent(name),
      language: this.language,
      fields: ['names'],
      check_name: true
    }
    return this.restApiService.getRawIngredient(params).pipe(
      map((ingredients) => {
        if (ingredients) return ingredients['ingredients']
        else return []
      })
    )
  }

  createRawIngredient(name: string): Observable<RawIngredient> {
    const params = {
      language: this.language
    }
    const raw_ingredient: RawIngredient = new RawIngredient()
    raw_ingredient.names[this.language].setValue(name)
    return this.restApiService.createRawIngredient(params, raw_ingredient.as_dict).pipe(
      tap((response) => {
        console.log(response['message'])
        raw_ingredient.id = response['ingredient_id']
      }),
      map(() => raw_ingredient)
    )
  }

  updateRawIngredient(raw_ingredient: RawIngredient): Observable<RawIngredient> {
    const params = {
      language: this.language
    }
    return this.restApiService.createRawIngredient(params, raw_ingredient.as_dict).pipe(
      tap((response) => {
        console.log(response['message'])
        raw_ingredient.id = response['ingredient_id']
        raw_ingredient.saving = false
        this.addToIngredients(raw_ingredient)
      }),
      map(() => this.raw_ingredient_with_id(raw_ingredient.id))
    )
  }

  addToIngredients(raw_ingredient: RawIngredient): void {
    const existing_raw_ingredient: RawIngredient = this.raw_ingredient_with_id(raw_ingredient.id)
    if (!existing_raw_ingredient) this.raw_ingredients.push(raw_ingredient)
    else existing_raw_ingredient.patchValue(raw_ingredient.as_dict)
  }

  raw_ingredient_with_id(raw_ingredient_id: string): RawIngredient {
    return this.raw_ingredients.find((raw_ingredient) => raw_ingredient.id == raw_ingredient_id)
  }
}
