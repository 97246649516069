import { Inject, Injectable, LOCALE_ID } from '@angular/core'
import { FormBuilder } from '@angular/forms'
import { merge, Observable, Subject } from 'rxjs'
import { catchError, map, scan, tap } from 'rxjs/operators'
import { MenuPrintTemplate } from './menu-print-template.model'
import { RestApiService } from '../../services/rest-api.service'
import { IMenuPrintTemplate } from '../../global.models'
import * as minimalisticTemplate from '../../assets/menu_print_templates/minimalistic.json'
import * as artisticTemplate from '../../assets/menu_print_templates/artistic.json'
import * as artisticLandscapeTemplate from '../../assets/menu_print_templates/artistic_landscape.json'
import * as classicTemplate from '../../assets/menu_print_templates/classic.json'
import * as classicLandscapeTemplate from '../../assets/menu_print_templates/classic_landscape.json'
import * as colorfulTemplate from '../../assets/menu_print_templates/colorful.json'
import * as standardTemplate from '../../assets/menu_print_templates/standard.json'
import * as standardCloudTemplate from '../../assets/menu_print_templates/standard_cloud.json'
import * as basicLandscapeTemplate from '../../assets/menu_print_templates/landscape.json'
import * as basicLandscapeCloudTemplate from '../../assets/menu_print_templates/landscape_cloud.json'
import * as simpleTemplate from '../../assets/menu_print_templates/simple.json'
import * as basicTemplate from '../../assets/menu_print_templates/basic.json'
import * as onePageTemplate from '../../assets/menu_print_templates/one_pager.json'
import * as typeWriterTemplate from '../../assets/menu_print_templates/typewriter.json'
import { SubsidiaryService } from '../subsidiary/subsidiary.service'

@Injectable({
  providedIn: 'root'
})
export class MenuPrintTemplatesService {
  public menuPrintTemplates: MenuPrintTemplate[] = []
  public standardTemplates: any[] = [
    { image: 'assets/menu_template_previews/classic.png', template: classicTemplate },
    { image: 'assets/menu_template_previews/minimalistic.png', template: minimalisticTemplate },
    { image: 'assets/menu_template_previews/artistic.png', template: artisticTemplate },
    { image: 'assets/menu_template_previews/colorful.png', template: colorfulTemplate },
    { image: 'assets/menu_template_previews/standard.png', template: standardTemplate },
    { image: 'assets/menu_template_previews/standard_cloud.png', template: standardCloudTemplate },
    { image: 'assets/menu_template_previews/simple.png', template: simpleTemplate },
    { image: 'assets/menu_template_previews/basic.png', template: basicTemplate },
    { image: 'assets/menu_template_previews/one_pager.png', template: onePageTemplate },
    { image: 'assets/menu_template_previews/typewriter.png', template: typeWriterTemplate },
    { image: 'assets/menu_template_previews/classic_landscape.png', template: classicLandscapeTemplate },
    { image: 'assets/menu_template_previews/artistic_landscape.png', template: artisticLandscapeTemplate },
    { image: 'assets/menu_template_previews/landscape.png', template: basicLandscapeTemplate },
    { image: 'assets/menu_template_previews/landscape_cloud.png', template: basicLandscapeCloudTemplate }
  ]

  private _newMenuPrintTemplates$: Subject<any> = new Subject<any>()

  constructor(public fb: FormBuilder, private restApiService: RestApiService, @Inject(LOCALE_ID) public language: string, private _subsidiaryService: SubsidiaryService) {}

  loadMenuPrintTemplates(): Observable<MenuPrintTemplate[]> {
    const params = {
      active: true
    }

    const loadPipe$ = this.restApiService.loadMenuPrintTemplates(params).pipe(catchError(() => []))

    return merge(loadPipe$, this._newMenuPrintTemplates$).pipe(
      scan((menuPrintTemplates: any[], newMenuPrintTemplates: any[]) => {
        if (!newMenuPrintTemplates) return this.menuPrintTemplates
        else {
          const map = new Map()
          menuPrintTemplates.forEach((menuPrintTemplate) => map.set(menuPrintTemplate.id, menuPrintTemplate))
          newMenuPrintTemplates.forEach((menuPrintTemplate) => map.set(menuPrintTemplate.id, { ...map.get(menuPrintTemplate.id), ...menuPrintTemplate }))
          return Array.from(map.values())
        }
      }),
      tap((menuPrintTemplates) => menuPrintTemplates?.sort((a, b) => (a.name > b.name ? 1 : -1))?.forEach((menuPrintTemplate) => this.addToMenuPrintTemplates(menuPrintTemplate))),
      map(() => this.menuPrintTemplates)
    )
  }

  addToMenuPrintTemplates(menuPrintTemplate: IMenuPrintTemplate): void {
    let existing_menu_print_template: MenuPrintTemplate = this.menuPrintTemplateWithId(menuPrintTemplate.id)
    if (!existing_menu_print_template) this.menuPrintTemplates.push(new MenuPrintTemplate(menuPrintTemplate))
  }

  menuPrintTemplateWithId(menuPrintTemplateId: string): MenuPrintTemplate {
    return this.menuPrintTemplates.find((menuPrintTemplate) => menuPrintTemplate.id == menuPrintTemplateId)
  }

  saveMenuPrintTemplate(menuPrintTemplate: MenuPrintTemplate): Observable<IMenuPrintTemplate> {
    if (menuPrintTemplate.isNew) {
      return this.restApiService.createMenuPrintTemplate(menuPrintTemplate.asDict, { subsidiary_id: this._subsidiaryService.subsidiary.id }).pipe(tap((response) => this._newMenuPrintTemplates$.next([response])))
    } else return this.restApiService.updateMenuPrintTemplate(menuPrintTemplate.asDict)
  }

  deleteTemplate(menuPrintTemplate: MenuPrintTemplate): Observable<any> {
    return this.restApiService.deleteMenuPrintTemplate({ menu_print_template_id: menuPrintTemplate.id }).pipe(
      tap(() => {
        this.menuPrintTemplates = this.menuPrintTemplates.filter((template) => template.id != menuPrintTemplate.id)
        this._newMenuPrintTemplates$.next(null)
      })
    )
  }
}
