import { MenuPrintTemplateImageElement } from '../../menu-print-template/elements/image-element.model'
import { MenuPrintTemplateImageElementFormat } from '../../menu-print-template/formats/image-format.model'
import { PrintDocument } from '../print-document.model'

export class PrintPageImageElement {
  constructor(public template: MenuPrintTemplateImageElement, public printDocument?: PrintDocument, public menuItemIdentifier?: string, public model?: any, public translation_path?: string[]) {}

  public get elementTemplate(): MenuPrintTemplateImageElement {
    return this.template
  }

  public get format(): MenuPrintTemplateImageElementFormat {
    return this.elementTemplate.format
  }

  public get imageObjectFit(): object {
    return {
      'object-fit': this.format.objectFit.value
    }
  }

  public get imageStyle(): any {
    return Object.assign({
      position: 'absolute',
      width: this.format.position.value == 'full-page' ? '100%' : (this.format.width.value / 210) * 100 + '%',
      height: this.format.position.value == 'full-page' ? '100%' : (((this.format.height.value / 210) * this.printDocument.width) / this.printDocument.height) * 100 + '%',
      opacity: this.format.opacity.value / 100
    })
  }

  public get imagePrintStyle(): any {
    return Object.assign(
      {
        position: 'absolute',
        width: this.format.position.value == 'full-page' ? '100%' : (this.format.width.value / 210) * 100 + '%',
        height: this.format.position.value == 'full-page' ? '100%' : (((this.format.height.value / 210) * this.printDocument.width) / this.printDocument.height) * 100 + '%',
        opacity: this.format.opacity.value / 100
      },
      this.imagePrintPosition
    )
  }

  public get imageDragPosition(): object {
    switch (this.format.position.value) {
      case 'center':
        return {
          x: this.printDocument.width / 2 - ((this.format.width.value / 210) * this.printDocument.width) / 2,
          y: this.printDocument.height / 2 - ((this.format.height.value / 210) * this.printDocument.width) / 2
        }
      case 'full-page':
        return {
          x: 0,
          y: 0
        }
      case 'top-left':
        return {
          x: (this.format.left.value / 210) * this.printDocument.width,
          y: (this.format.top.value / 210) * this.printDocument.width
        }
      case 'top-right':
        return {
          x: (1 - this.format.right.value / 210) * this.printDocument.width - (this.format.width.value / 210) * this.printDocument.width,
          y: (this.format.top.value / 210) * this.printDocument.width
        }
      case 'bottom-left':
        return {
          x: (this.format.left.value / 210) * this.printDocument.width,
          y: this.printDocument.height - (this.format.bottom.value / 210) * this.printDocument.width - (this.format.height.value / 210) * this.printDocument.width
        }
      case 'bottom-right':
        return {
          x: (1 - this.format.right.value / 210) * this.printDocument.width - (this.format.width.value / 210) * this.printDocument.width,
          y: this.printDocument.height - (this.format.bottom.value / 210) * this.printDocument.width - (this.format.height.value / 210) * this.printDocument.width
        }
    }
  }

  public get imagePrintPosition(): object {
    switch (this.format.position.value) {
      case 'center':
        return {
          left: 792 / 2 - ((this.format.width.value / 210) * 792) / 2,
          top: 792 / 2 - ((this.format.height.value / 210) * 792) / 2
        }
      case 'full-page':
        return {
          left: 0,
          top: 0
        }
      case 'top-left':
        return {
          left: (this.format.left.value / 210) * 792 + 'px',
          top: (this.format.top.value / 210) * 792 + 'px'
        }
      case 'top-right':
        return {
          right: (this.format.right.value / 210) * 792 + 'px',
          top: (this.format.top.value / 210) * 792 + 'px'
        }
      case 'bottom-left':
        return {
          left: (this.format.left.value / 210) * 792 + 'px',
          bottom: (this.format.bottom.value / 210) * 792 + 'px'
        }
      case 'bottom-right':
        return {
          right: (this.format.right.value / 210) * 792 + 'px',
          bottom: (this.format.bottom.value / 210) * 792 + 'px'
        }
    }
  }
}
