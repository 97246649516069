import { IMenuPrintTemplateElement } from '../../../global.models'
import { BasicMenuPrintTemplateElement } from './basic-element.model'
import { MenuPrintTemplateStringElementFormat } from '../formats/string-format.model'
import { MenuTemplate } from '../../menu-templates/menu-template.model'

export class MenuPrintTemplateMenuTitleElement extends BasicMenuPrintTemplateElement {
  public format: MenuPrintTemplateStringElementFormat
  public translatable = true
  public name: string = $localize`Menu Titel`

  constructor(element: IMenuPrintTemplateElement) {
    super('menuTitle')

    this.format = new MenuPrintTemplateStringElementFormat(element?.format)
  }

  public get asDict(): IMenuPrintTemplateElement {
    return {
      type: this.type,
      format: this.format.asDict
    }
  }

  public elementString(menuTemplate: MenuTemplate, language: string): string {
    return this.func.caseString(menuTemplate.names[language].value, this.format.capitalization.value)
  }
}
