import { Injectable } from '@angular/core'
import { Recipe } from './recipe.model'
import { RecipePortion } from './recipe-portion.model'
import { merge } from 'rxjs'
import { RecipePortionIngredient } from '../raw-ingredient/recipe-portion-ingredient.model'
import { RecipePortionSubRecipe } from './recipe-portion-sub-recipe.model'

@Injectable({
  providedIn: 'root'
})
export class RecipesScalingService {
  public listenForPortionScaling(recipe: Recipe): void {
    this._listenForPortionServingsChanges(recipe)
    this._listenForPortionSizeChanges(recipe)

    this._listenForTotalIngredientAmountChanges(recipe)
    this._listenForTotalSubRecipeAmountChanges(recipe)

    this._listenForIngredientBruttoAmountChanges(recipe)
    this._listenForSubRecipeBruttoAmountChanges(recipe)

    this._listenForIngredientUnitChanges(recipe)
    this._listenForSubRecipeUnitChanges(recipe)

    this._listenForIngredientShrinkageChanges(recipe)
    this._listenForIngredientPreparationFactorChanges(recipe)
    this._listenForIngredientNettoFactorChanges(recipe)

    this._listenForIngredientCalculatedShareChanges(recipe)
  }

  public updateTotalIngredientBruttoAmountBasedOnIngredientBruttoAmounts(recipe: Recipe): void {
    recipe.total_ingredient_brutto_amount_in_kg.setValue(
      recipe.func.round(
        recipe.portions.map((portion) => portion.portion_ingredients_brutto_amount).reduce((a, b) => a + b, 0),
        2
      ),
      { emitEvent: false }
    )
  }

  public updateTotalSubRecipesBruttoAmountBasedOnSubRecipeBruttoAmounts(recipe: Recipe): void {
    recipe.total_sub_recipe_brutto_amount_in_kg.setValue(
      recipe.func.round(
        recipe.portions.map((portion) => portion.portion_sub_recipes_brutto_amount).reduce((a, b) => a + b, 0),
        2
      ),
      { emitEvent: false }
    )
  }

  public updatePortionSizesBasedOnNettoAmounts(recipe: Recipe): void {
    recipe.portions.forEach((recipe_portion) => {
      if (recipe.func.roundString(recipe_portion.servings.value) == 0) recipe_portion.servings.setValue('100', { emitEvent: false })
      recipe_portion.portion_size.setValue(recipe.func.round((recipe_portion.sum_of_portion_netto_amounts_in_kg * 1000) / recipe_portion.servings_long, 0).toString(), { emitEvent: false })
      recipe_portion.portion_size_long = (recipe_portion.sum_of_portion_netto_amounts_in_kg * 1000) / recipe.func.roundString(recipe_portion.servings.value)
    })
  }

  public updatePortionServingsBasedOnNettoAmounts(recipe: Recipe): void {
    recipe.portions.forEach((recipe_portion) => {
      if (recipe.func.roundString(recipe_portion.portion_size.value) == 0) recipe_portion.portion_size.setValue('100', { emitEvent: false })
      recipe_portion.servings.setValue(recipe.func.round((recipe_portion.sum_of_portion_netto_amounts_in_kg * 1000) / recipe_portion.portion_size_long, 0).toString(), { emitEvent: false })
      recipe_portion.servings_long = (recipe_portion.sum_of_portion_netto_amounts_in_kg * 1000) / recipe.func.roundString(recipe_portion.portion_size.value)
    })
  }

  // Listeners
  private _listenForPortionSizeChanges(recipe: Recipe): void {
    if (recipe.portion_sizes_subscription) recipe.portion_sizes_subscription.unsubscribe()
    recipe.portion_sizes_subscription = merge(...recipe.portions.map((portion) => portion.portion_size.valueChanges)).subscribe(() => {
      this._updatePortionsBasedOnPortionSizes(recipe)
      this._updateTotalIngredientBruttoAmount(recipe)
    })
  }

  private _listenForPortionServingsChanges(recipe: Recipe): void {
    if (recipe.portion_servings_subscription) recipe.portion_servings_subscription.unsubscribe()
    recipe.portion_servings_subscription = merge(...recipe.portions.map((portion) => portion.servings.valueChanges)).subscribe(() => {
      this._updatePortionsBasedOnServings(recipe)
      this._updateTotalIngredientBruttoAmount(recipe)
    })
  }

  private _listenForIngredientBruttoAmountChanges(recipe: Recipe): void {
    if (recipe.ingredient_brutto_amount_subscription) recipe.ingredient_brutto_amount_subscription.unsubscribe()
    recipe.ingredient_brutto_amount_subscription = merge(...[].concat.apply(recipe.portions.map((portion) => portion.portion_ingredients.map((ingredient) => ingredient.amount_rounded.valueChanges))).flat()).subscribe(() => {
      this._updatePortionIngredientBruttoAmountsBasedOnUserInput(recipe)
      this._updateSubIngredientCalculatedShares(recipe)
      this.updateTotalIngredientBruttoAmountBasedOnIngredientBruttoAmounts(recipe)
      this._updatePortionServingSizes(recipe)
    })
  }

  private _listenForSubRecipeBruttoAmountChanges(recipe: Recipe): void {
    if (recipe.sub_recipe_brutto_amount_subscription) recipe.sub_recipe_brutto_amount_subscription.unsubscribe()
    recipe.sub_recipe_brutto_amount_subscription = merge(...[].concat.apply(recipe.portions.map((portion) => portion.portion_sub_recipes.map((sub_recipe) => sub_recipe.amount_rounded.valueChanges))).flat()).subscribe((change) => {
      this._updatePortionSubRecipeBruttoAmountsBasedOnUserInput(recipe)
      this._updateSubIngredientCalculatedShares(recipe)
      this.updateTotalSubRecipesBruttoAmountBasedOnSubRecipeBruttoAmounts(recipe)
      this._updatePortionServingSizes(recipe)
    })
  }

  // FIX: When deleting the total ingredient amount, portion shares are reset to be equal
  private _listenForTotalIngredientAmountChanges(recipe: Recipe): void {
    if (recipe.total_ingredient_brutto_amount_subscription) recipe.total_ingredient_brutto_amount_subscription.unsubscribe()
    recipe.total_ingredient_brutto_amount_subscription = recipe.total_ingredient_brutto_amount_in_kg.valueChanges.subscribe(() => {
      this._updatePortionIngredientBruttoAmountsBasedOnCalculatedShares(recipe)
      if (!recipe.portion_size_locked) this._updatePortionSizesBasedOnServingSizes(recipe)
      else if (!recipe.servings_locked) this._updatePortionServingsBasedOnServingSizes(recipe)
    })
  }

  private _listenForTotalSubRecipeAmountChanges(recipe: Recipe): void {
    if (recipe.total_sub_recipe_brutto_amount_subscription) recipe.total_sub_recipe_brutto_amount_subscription.unsubscribe()
    recipe.total_sub_recipe_brutto_amount_subscription = recipe.total_sub_recipe_brutto_amount_in_kg.valueChanges.subscribe(() => {
      this._updateSubRecipePortionAmountsBasedOnCalculatedShares(recipe)
      if (!recipe.portion_size_locked) this._updatePortionSizesBasedOnServingSizes(recipe)
      else if (!recipe.servings_locked) this._updatePortionServingsBasedOnServingSizes(recipe)
    })
  }

  private _listenForSubRecipeUnitChanges(recipe: Recipe): void {
    if (recipe.sub_recipe_unit_subscription) recipe.sub_recipe_unit_subscription.unsubscribe()
    recipe.sub_recipe_unit_subscription = merge(...recipe.sub_recipes.map((sub_recipe) => sub_recipe.unit.valueChanges)).subscribe(() => {
      this._updatePortionServingSizes(recipe)
      this._updateTotalSubRecipeBruttoAmountBasedOnServingSizes(recipe)
      this._updateSubIngredientCalculatedShares(recipe)
      recipe.updateRecipeCalculations()
    })
  }

  private _listenForIngredientUnitChanges(recipe: Recipe): void {
    if (recipe.ingredient_unit_subscription) recipe.ingredient_unit_subscription.unsubscribe()
    recipe.ingredient_unit_subscription = merge(...recipe.ingredients.map((ingredient) => ingredient.unit.valueChanges)).subscribe(() => {
      this._updatePortionServingSizes(recipe)
      this._updateTotalIngredientBruttoAmountBasedOnServingSizes(recipe)
      this._updateSubIngredientCalculatedShares(recipe)
      recipe.updateRecipeCalculations()
    })
  }

  private _listenForIngredientShrinkageChanges(recipe: Recipe): void {
    if (recipe.ingredient_shrinkage_subscription) recipe.ingredient_shrinkage_subscription.unsubscribe()
    recipe.ingredient_shrinkage_subscription = merge(...recipe.ingredients_and_sub_recipes.map((sub_ingredient) => sub_ingredient.shrinkage.valueChanges)).subscribe(() => {
      this._updateIngredientNettoFactorBasedOnPreparationFactor(recipe)
      this._updateSubRecipeNettoFactorBasedOnPreparationFactor(recipe)

      if (!recipe.portion_size_locked) this._updatePortionSizesBasedOnServingSizes(recipe)
      else if (!recipe.servings_locked) this._updatePortionServingsBasedOnServingSizes(recipe)

      recipe.nutrition = recipe.calculateNutrition()
    })
  }

  private _listenForIngredientPreparationFactorChanges(recipe: Recipe): void {
    if (recipe.ingredient_preparation_subscription) recipe.ingredient_preparation_subscription.unsubscribe()
    recipe.ingredient_preparation_subscription = merge(...recipe.ingredients_and_sub_recipes.map((sub_ingredient) => sub_ingredient.preparation_factor.valueChanges)).subscribe(() => {
      this._updateIngredientNettoFactorBasedOnPreparationFactor(recipe)
      this._updateSubRecipeNettoFactorBasedOnPreparationFactor(recipe)

      if (!recipe.portion_size_locked) this._updatePortionSizesBasedOnServingSizes(recipe)
      else if (!recipe.servings_locked) this._updatePortionServingsBasedOnServingSizes(recipe)

      recipe.nutrition = recipe.calculateNutrition()
    })
  }

  private _listenForIngredientNettoFactorChanges(recipe: Recipe): void {
    if (recipe.ingredient_netto_factor_subscription) recipe.ingredient_netto_factor_subscription.unsubscribe()
    recipe.ingredient_netto_factor_subscription = merge(...recipe.ingredients_and_sub_recipes.map((sub_ingredient) => sub_ingredient.netto_factor.valueChanges)).subscribe(() => {
      this._updateIngredientPreparationFactorBasedOnNettoFactor(recipe)
      this._updateSubRecipePreparationFactorBasedOnNettoFactor(recipe)

      if (!recipe.portion_size_locked) this._updatePortionSizesBasedOnServingSizes(recipe)
      else if (!recipe.servings_locked) this._updatePortionServingsBasedOnServingSizes(recipe)

      recipe.nutrition = recipe.calculateNutrition()
    })
  }

  private _listenForIngredientCalculatedShareChanges(recipe: Recipe): void {
    if (recipe.ingredient_share_subscription) recipe.ingredient_share_subscription.unsubscribe()
    recipe.ingredient_share_subscription = merge(...[].concat.apply(recipe.portions.map((portion) => portion.portion_ingredients_and_sub_recipes.map((sub_ingredient) => sub_ingredient.calculated_share.valueChanges))).flat()).subscribe(() => {
      recipe.updateRecipeCalculations()
    })
  }

  // Updaters
  private _updatePortionServingSizes(recipe: Recipe): void {
    if (!recipe.portion_size_locked) this._updatePortionSizesBasedOnIngredientNettoAmounts(recipe)
    else if (!recipe.servings_locked) {
      this._updatePortionServingsBasedOnIngredientNettoAmounts(recipe)
      recipe.custom_portions = true
    }
  }
  private _updatePortionsBasedOnPortionSizes(recipe: Recipe): void {
    this._updatePortionSizesBasedOnUserInput(recipe)
    if (!recipe.servings_locked) {
      this.updatePortionServingsBasedOnNettoAmounts(recipe)
      recipe.custom_portions = true
    } else this._updatePortionIngredientBruttoAmounts(recipe)
  }

  private _updatePortionSizesBasedOnUserInput(recipe: Recipe): void {
    recipe.portions
      .filter((portion) => recipe.func.roundString(portion.portion_size.value) > 0)
      .forEach((portion) => {
        portion.portion_size_long = recipe.func.roundString(portion.portion_size.value)
      })
  }

  private _updatePortionsBasedOnServings(recipe: Recipe): void {
    this._updatePortionServingsBasedOnUserInput(recipe)
    if (!recipe.portion_size_locked) this.updatePortionSizesBasedOnNettoAmounts(recipe)
    else this._updatePortionIngredientBruttoAmounts(recipe)
  }

  private _updatePortionServingsBasedOnUserInput(recipe: Recipe): void {
    recipe.portions
      .filter((portion) => recipe.func.roundString(portion.servings.value) > 0)
      .forEach((portion) => {
        portion.servings_long = recipe.func.roundString(portion.servings.value)
      })
  }

  private _updateTotalIngredientBruttoAmount(recipe: Recipe): void {
    if (recipe.are_total_portion_amounts_valid) {
      if (recipe.sum_of_portion_unlocked_sub_ingredient_shares == 0) this._updateTotalIngredientBruttoAmountBasedOnServingSizes(recipe)
      else {
        this.updateTotalIngredientBruttoAmountBasedOnIngredientBruttoAmounts(recipe)
        this.updateTotalSubRecipesBruttoAmountBasedOnSubRecipeBruttoAmounts(recipe)
      }
      recipe.custom_portions = true
    }
  }

  private _updateTotalIngredientBruttoAmountBasedOnServingSizes(recipe: Recipe): void {
    recipe.total_ingredient_brutto_amount_in_kg.setValue(
      recipe.func.round(
        recipe.portions.map((portion) => (portion.servings_long * portion.portion_size_long) / 1000 - portion.portion_sub_recipes_brutto_amount).reduce((a, b) => a + b, 0),
        2
      ),
      { emitEvent: false }
    )
  }

  private _updateTotalSubRecipeBruttoAmountBasedOnServingSizes(recipe: Recipe): void {
    recipe.total_sub_recipe_brutto_amount_in_kg.setValue(
      recipe.func.round(
        recipe.portions.map((portion) => (portion.servings_long * portion.portion_size_long) / 1000 - portion.portion_ingredients_brutto_amount).reduce((a, b) => a + b, 0),
        2
      ),
      { emitEvent: false }
    )
  }

  private _updatePortionIngredientBruttoAmountsBasedOnCalculatedShares(recipe: Recipe): void {
    const old_brutto_amount = recipe.portions.map((portion) => portion.portion_ingredients_brutto_amount).reduce((a, b) => a + b, 0)
    const sum_of_portion_netto_amounts_based_on_serving_sizes: number = recipe.sum_of_portion_netto_amounts_based_on_serving_sizes
    let portion_shares_of_total_amount = recipe.portions.map((portion) => (old_brutto_amount ? portion.portion_ingredients_brutto_amount / old_brutto_amount : portion.portion_share(sum_of_portion_netto_amounts_based_on_serving_sizes)))

    recipe.portions.forEach((recipe_portion, recipe_portion_index) => {
      recipe_portion.unlocked_portion_ingredients.forEach((ingredient) => {
        ingredient.setBruttoAmountBasedOnCalculatedShares((parseFloat(recipe.total_ingredient_brutto_amount_in_kg.value) || 0) * 1000 * portion_shares_of_total_amount[recipe_portion_index], recipe_portion.sum_of_unlocked_ingredient_shares)
      })
    })
  }
  private _updatePortionIngredientBruttoAmountsBasedOnUserInput(recipe: Recipe): void {
    recipe.portions.forEach((portion) => {
      portion.portion_ingredients.forEach((portion_ingredient) => {
        portion_ingredient.updateIngredientBruttoAmountsBasedOnUserInput()
      })
    })
  }

  private _updatePortionSubRecipeBruttoAmountsBasedOnUserInput(recipe: Recipe): void {
    recipe.portions.forEach((portion) => {
      portion.portion_sub_recipes.forEach((sub_recipe) => {
        sub_recipe.updateBruttoAmountBasedOnUserInput()
      })
    })
  }

  private _updateSubIngredientCalculatedShares(recipe: Recipe): void {
    recipe.portions.forEach((recipe_portion) => {
      recipe_portion.portion_ingredients_and_sub_recipes.forEach((sub_ingredient) => {
        sub_ingredient.updateIngredientCalculatedShares(recipe_portion.portion_brutto_amount)
      })
    })
  }

  private _updateIngredientPreparationFactorBasedOnNettoFactor(recipe: Recipe): void {
    recipe.ingredients.forEach((ingredient) => {
      ingredient.preparation_factor.setValue(recipe.func.round(recipe.func.roundString(ingredient.netto_factor.value?.length ? ingredient.netto_factor.value : '1') / recipe.func.roundString(ingredient.shrinkage.value), 2).toString(), { emitEvent: false })
    })
  }
  private _updateSubRecipePreparationFactorBasedOnNettoFactor(recipe: Recipe): void {
    recipe.sub_recipes?.forEach((sub_recipe) => {
      sub_recipe.preparation_factor.setValue(recipe.func.round(recipe.func.roundString(sub_recipe.netto_factor.value?.length ? sub_recipe.netto_factor.value : '1') / recipe.func.roundString(sub_recipe.shrinkage.value), 2).toString(), { emitEvent: false })
    })
  }
  private _updateIngredientNettoFactorBasedOnPreparationFactor(recipe: Recipe): void {
    recipe.ingredients.forEach((ingredient) => {
      ingredient.netto_factor.setValue(recipe.func.round(recipe.func.roundString(ingredient.shrinkage.value) * recipe.func.roundString(ingredient.preparation_factor.value), 2).toString(), { emitEvent: false })
    })
  }
  private _updateSubRecipeNettoFactorBasedOnPreparationFactor(recipe: Recipe): void {
    recipe.sub_recipes?.forEach((sub_recipe) => {
      sub_recipe.netto_factor.setValue(recipe.func.round(recipe.func.roundString(sub_recipe.shrinkage.value) * recipe.func.roundString(sub_recipe.preparation_factor.value), 2).toString(), { emitEvent: false })
    })
  }

  // FIX: This is a duplicate of the function in the recipe-portion.service.ts
  // FIX: Rounding seems to be a bit odd
  private _updatePortionSizesBasedOnIngredientNettoAmounts(recipe: Recipe): void {
    recipe.portions.forEach((recipe_portion) => {
      if (recipe_portion.func.roundString(recipe_portion.servings.value) == 0) recipe_portion.servings.setValue('100', { emitEvent: false })
      recipe_portion.portion_size.setValue(recipe_portion.func.round((recipe_portion.sum_of_portion_netto_amounts_in_kg * 1000) / recipe_portion.servings_long, 0).toString(), { emitEvent: false })
      recipe_portion.portion_size_long = (recipe_portion.sum_of_portion_netto_amounts_in_kg * 1000) / recipe_portion.func.roundString(recipe_portion.servings.value)
    })
  }

  // FIX: This is a duplicate of the function in the recipe-portion.service.ts
  // FIX: Rounding seems to be a bit odd
  private _updatePortionServingsBasedOnIngredientNettoAmounts(recipe: Recipe): void {
    recipe.portions.forEach((recipe_portion) => {
      if (recipe_portion.func.roundString(recipe_portion.portion_size.value) == 0) recipe_portion.portion_size.setValue('100', { emitEvent: false })
      recipe_portion.servings.setValue(recipe_portion.func.round((recipe_portion.sum_of_portion_netto_amounts_in_kg * 1000) / recipe_portion.portion_size_long, 0).toString(), { emitEvent: false })
      recipe_portion.servings_long = (recipe_portion.sum_of_portion_netto_amounts_in_kg * 1000) / recipe_portion.func.roundString(recipe_portion.portion_size.value)
    })
  }

  private _updatePortionSizesBasedOnServingSizes(recipe: Recipe): void {
    const sum_of_portion_netto_amounts_based_on_serving_sizes = recipe.sum_of_portion_netto_amounts_based_on_serving_sizes
    const total_netto_amount = recipe.sum_of_portions_netto_amounts_in_g
    recipe.portions.forEach((recipe_portion, recipe_portion_index) => {
      const portion_share_of_total_amount = sum_of_portion_netto_amounts_based_on_serving_sizes ? recipe_portion.portion_netto_amount_based_on_serving_sizes / sum_of_portion_netto_amounts_based_on_serving_sizes : this._portion_amount_share(recipe, recipe_portion_index)
      const portion_netto_amount = total_netto_amount * portion_share_of_total_amount
      recipe_portion.portion_size_long = portion_netto_amount / recipe_portion.servings_long
      recipe_portion.portion_size.setValue(recipe.func.round(recipe_portion.portion_size_long, 0).toString(), { emitEvent: false })
    })
  }
  private _updatePortionServingsBasedOnServingSizes(recipe: Recipe): void {
    const sum_of_portion_netto_amounts_based_on_serving_sizes = recipe.sum_of_portion_netto_amounts_based_on_serving_sizes
    const total_netto_amount = recipe.sum_of_portions_netto_amounts_in_g
    recipe.portions.forEach((recipe_portion, recipe_portion_index) => {
      const portion_share_of_total_amount = sum_of_portion_netto_amounts_based_on_serving_sizes ? recipe_portion.portion_netto_amount_based_on_serving_sizes / sum_of_portion_netto_amounts_based_on_serving_sizes : this._portion_amount_share(recipe, recipe_portion_index)
      const portion_netto_amount = total_netto_amount * portion_share_of_total_amount
      recipe_portion.servings_long = portion_netto_amount / recipe_portion.portion_size_long
      recipe_portion.servings.setValue(recipe.func.round(recipe_portion.servings_long, 0).toString(), { emitEvent: false })
    })
  }

  private _updateSubRecipePortionAmountsBasedOnCalculatedShares(recipe: Recipe): void {
    const sum_of_portion_netto_amounts_based_on_serving_sizes = recipe.sum_of_portion_netto_amounts_based_on_serving_sizes
    const old_brutto_amount = recipe.portions.map((portion) => portion.portion_brutto_amount).reduce((a, b) => a + b, 0)
    const portion_shares_of_total_amount = recipe.portions.map((recipe_portion) => (old_brutto_amount ? recipe_portion.portion_brutto_amount / old_brutto_amount : recipe_portion.portion_share(sum_of_portion_netto_amounts_based_on_serving_sizes)))
    recipe.portions.forEach((recipe_portion, recipe_portion_index) => {
      recipe_portion.unlocked_portion_sub_recipes.forEach((sub_recipe) => {
        sub_recipe.setBruttoAmountBasedOnCalculatedShares((parseFloat(recipe.total_sub_recipe_brutto_amount_in_kg.value) || 0) * 1000 * portion_shares_of_total_amount[recipe_portion_index], recipe_portion.sum_of_unlocked_sub_recipe_shares)
      })
    })
  }

  private _updatePortionIngredientBruttoAmounts(recipe: Recipe): void {
    recipe.portions
      .filter((portion) => recipe.func.roundString(portion.portion_size.value) > 0 && recipe.func.roundString(portion.servings.value) > 0)
      .forEach((portion) => {
        if (this._can_any_portion_ingredients_be_updated(recipe, portion)) this._updateIngredientBruttoAmounts(portion)
        else if (portion.is_total_amount_valid) {
          if (portion.sum_of_unlocked_sub_ingredient_shares == 0 && recipe.default_portion.sum_of_unlocked_sub_ingredient_shares > 0) {
            this._patchCalculatedSharesFromDefaultPortion(portion, recipe.default_portion.portion_ingredients, recipe.default_portion.portion_sub_recipes)
            this._updateIngredientBruttoAmounts(portion)
          } else {
            this._updateTotalIngredientBruttoAmountBasedOnServingSizes(recipe)
          }
        } else if (!portion.is_total_amount_valid) {
          console.log('Invalid input')
        }
      })
  }

  private _patchCalculatedSharesFromDefaultPortion(recipe_portion: RecipePortion, default_portion_ingredients: RecipePortionIngredient[], default_portion_sub_recipes: RecipePortionSubRecipe[]): void {
    recipe_portion.portion_ingredients.forEach((portion_ingredient) => {
      const match = default_portion_ingredients.find((default_portion_ingredient) => default_portion_ingredient.object.id == portion_ingredient.object.id)
      if (match) portion_ingredient.calculated_share.setValue(match.calculated_share.value, { emitEvent: false })
    })
    recipe_portion.portion_sub_recipes.forEach((portion_sub_recipe) => {
      const match = default_portion_sub_recipes.find((default_portion_sub_recipe) => default_portion_sub_recipe.object.id == portion_sub_recipe.object.id)
      if (match) portion_sub_recipe.calculated_share.setValue(match.calculated_share.value, { emitEvent: false })
    })
  }

  private _updateIngredientBruttoAmounts(recipe_portion: RecipePortion): void {
    const total_portion_netto_amount = (recipe_portion.servings_long * recipe_portion.portion_size_long) / 1000
    const diff_netto_amount_to_distribute: number = total_portion_netto_amount - recipe_portion.sum_of_sub_ingredient_netto_amounts
    const sum_of_sub_ingredient_netto_amounts = recipe_portion.sum_of_sub_ingredient_netto_amounts
    const sum_of_unlocked_sub_ingredient_netto_shares: number = recipe_portion.unlocked_portion_ingredients_and_sub_recipes.map((sub_ingredient) => sub_ingredient.calculated_netto_share(sum_of_sub_ingredient_netto_amounts)).reduce((a, b) => a + b, 0)
    const sum_of_unlocked_sub_ingredient_calculated_shares: number = recipe_portion.unlocked_portion_ingredients_and_sub_recipes.map((sub_ingredient) => sub_ingredient.calculated_share.value).reduce((a, b) => a + b, 0)
    if (sum_of_unlocked_sub_ingredient_netto_shares) {
      recipe_portion.unlocked_portion_ingredients
        .filter((portion_ingredient) => portion_ingredient.calculated_share.value != undefined)
        .forEach((portion_ingredient) => {
          portion_ingredient.updateBruttoAmountBasedOnNettoDiff(diff_netto_amount_to_distribute * (portion_ingredient.calculated_netto_share(sum_of_sub_ingredient_netto_amounts) / sum_of_unlocked_sub_ingredient_netto_shares))
        })
      recipe_portion.unlocked_portion_sub_recipes
        .filter((sub_recipe) => sub_recipe.calculated_share.value != undefined)
        .forEach((sub_recipe) => {
          sub_recipe.updateBruttoAmountBasedOnNettoDiff(diff_netto_amount_to_distribute * (sub_recipe.calculated_netto_share(sum_of_sub_ingredient_netto_amounts) / sum_of_unlocked_sub_ingredient_netto_shares))
          let sub_recipe_portion = sub_recipe.object.portions.find((sub_recipe_portion: RecipePortion) => sub_recipe_portion.id == recipe_portion.id)
          if (sub_recipe_portion) {
            sub_recipe_portion.servings_long = recipe_portion.servings_long
            sub_recipe_portion.portion_size_long = recipe_portion.portion_size_long * (sub_recipe.calculated_share.value / 100)
            this._scaleSubRecipeIngredients(sub_recipe_portion)
          }
        })
    } else if (sum_of_unlocked_sub_ingredient_calculated_shares) {
      recipe_portion.unlocked_portion_ingredients
        .filter((portion_ingredient) => portion_ingredient.calculated_share.value != undefined)
        .forEach((portion_ingredient) => {
          portion_ingredient.updateBruttoAmountBasedOnNettoDiff(diff_netto_amount_to_distribute * (portion_ingredient.calculated_share.value / sum_of_unlocked_sub_ingredient_calculated_shares))
        })
      recipe_portion.unlocked_portion_sub_recipes
        .filter((sub_recipe) => sub_recipe.calculated_share.value != undefined)
        .forEach((sub_recipe) => {
          sub_recipe.updateBruttoAmountBasedOnNettoDiff(diff_netto_amount_to_distribute * (sub_recipe.calculated_share.value / sum_of_unlocked_sub_ingredient_calculated_shares))
          let sub_recipe_portion = sub_recipe.object.portions.find((sub_recipe_portion: RecipePortion) => sub_recipe_portion.id == recipe_portion.id)
          if (sub_recipe_portion) {
            sub_recipe_portion.servings_long = recipe_portion.servings_long
            sub_recipe_portion.portion_size_long = recipe_portion.portion_size_long * (sub_recipe.calculated_share.value / 100)
            this._scaleSubRecipeIngredients(sub_recipe_portion)
          }
        })
    }
    if (recipe_portion.portion_netto_amount_based_on_serving_sizes) this._updateIngredientCalculatedShares(recipe_portion)
  }

  private _scaleSubRecipeIngredients(recipe_portion: RecipePortion): void {
    recipe_portion.portion_ingredients.forEach((portion_ingredient: RecipePortionIngredient) => {
      portion_ingredient.setBruttoAmountBasedOnCalculatedShares(recipe_portion.portion_netto_amount_based_on_serving_sizes, recipe_portion.sum_of_ingredient_shares)
    })
  }

  private _updateIngredientCalculatedShares(recipe_portion: RecipePortion): void {
    recipe_portion.portion_ingredients_and_sub_recipes.forEach((sub_ingredient) => {
      sub_ingredient.updateIngredientCalculatedShares(recipe_portion.portion_brutto_amount)
    })
  }

  private _can_any_portion_ingredients_be_updated(recipe: Recipe, portion: RecipePortion): boolean {
    return !recipe.amount_locked_for_all_sub_ingredients && portion.sum_of_unlocked_sub_ingredient_shares > 0 && portion.is_total_amount_valid
  }

  private _portion_amount_share(recipe: Recipe, index: number): number {
    if (recipe.subsidiary_has_portion_templates && index < recipe.subsidiaryService.subsidiary?.portion_templates?.length)
      return recipe.subsidiaryService.subsidiary.portion_templates[index].servings.value / recipe.subsidiaryService?.subsidiary.portion_templates.map((portion_template) => portion_template.servings.value).reduce((a, b) => a + b, 0)
    else return 0
  }
}
