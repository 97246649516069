import { PrintDocument } from '../print-document/print-document.model'
import { PrintPageImageElement } from './print-page-elements/image-element.model'
import { PrintPageStringElement } from './print-page-elements/string-element.model'

export class PrintPage {
  headerElements: PrintPageStringElement[]
  bodyElements: PrintPageStringElement[]
  footerElements: PrintPageStringElement[]
  imageElements: PrintPageImageElement[]
  contentHeight: number

  constructor(public document: PrintDocument) {
    this.headerElements = this.document.generatePrintPageElementsFromTemplate(this.document.menuPrintTemplate.template.header.elements).flat().flat()
    this.bodyElements = []
    this.footerElements = this.document.generatePrintPageElementsFromTemplate(this.document.menuPrintTemplate.template.footer.elements).flat().flat()
    this.imageElements = (this.document.menuPrintTemplate.template.imageElements || []).map((element) => new PrintPageImageElement(element, this.document))
    this.contentHeight = 0
  }

  public get availableContentHeight(): number {
    return (this.document.firstPageElement.clientHeight - this._headerHeight - this.footerHeight) * (1 - this.document.menuPrintTemplate.template.body.format.marginTop.value / 210) * (1 - this.document.menuPrintTemplate.template.body.format.marginBottom.value / 210)
  }

  private get _headerHeight(): number {
    return (
      this.headerElements.reduce((acc, element) => acc + element.componentDOMSize, 0) +
      this.document.firstPageElement.clientWidth * (this.document.menuPrintTemplate.template.header.format.marginTop.value / 210) +
      this.document.firstPageElement.clientWidth * (this.document.menuPrintTemplate.template.header.format.marginBottom.value / 210)
    )
  }
  private get footerHeight(): number {
    return (
      this.footerElements.reduce((acc, element) => acc + element.componentDOMSize, 0) +
      this.document.firstPageElement.clientWidth * (this.document.menuPrintTemplate.template.footer.format.marginTop.value / 210) +
      this.document.firstPageElement.clientWidth * (this.document.menuPrintTemplate.template.footer.format.marginBottom.value / 210)
    )
  }

  public get headerStyle(): any {
    return {
      'background-color': this.document.menuPrintTemplate.template.header.format.backgroundColor.value,
      'padding-top': (this.document.menuPrintTemplate.template.header.format.marginTop.value / 210) * 100 + '%',
      'padding-right': (this.document.menuPrintTemplate.template.header.format.marginRight.value / 210) * 100 + '%',
      'padding-bottom': (this.document.menuPrintTemplate.template.header.format.marginBottom.value / 210) * 100 + '%',
      'padding-left': (this.document.menuPrintTemplate.template.header.format.marginLeft.value / 210) * 100 + '%'
    }
  }

  public get bodyStyle(): any {
    return {
      'background-color': this.document.menuPrintTemplate.template.body.format.backgroundColor.value,
      'padding-top': (this.document.menuPrintTemplate.template.body.format.marginTop.value / 210) * 100 + '%',
      'padding-right': (this.document.menuPrintTemplate.template.body.format.marginRight.value / 210) * 100 + '%',
      'padding-bottom': (this.document.menuPrintTemplate.template.body.format.marginBottom.value / 210) * 100 + '%',
      'padding-left': (this.document.menuPrintTemplate.template.body.format.marginLeft.value / 210) * 100 + '%'
    }
  }

  public get footerStyle(): any {
    return {
      'background-color': this.document.menuPrintTemplate.template.footer.format.backgroundColor.value,
      'padding-top': (this.document.menuPrintTemplate.template.footer.format.marginTop.value / 210) * 100 + '%',
      'padding-right': (this.document.menuPrintTemplate.template.footer.format.marginRight.value / 210) * 100 + '%',
      'padding-bottom': (this.document.menuPrintTemplate.template.footer.format.marginBottom.value / 210) * 100 + '%',
      'padding-left': (this.document.menuPrintTemplate.template.footer.format.marginLeft.value / 210) * 100 + '%'
    }
  }
}
