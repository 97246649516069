import { FormBuilder, FormControl } from '@angular/forms'
import { Names } from '../names/names.model'
import { RawIngredient } from '../raw-ingredient/raw-ingredient.model'
import { Allergen } from '../allergen/allergen.model'
import { merge, Observable, of, Subscription } from 'rxjs'
import moment from 'moment'
import { RecipeProductionStep } from './recipe-production-step.model'
import { DishCatalogue } from '../dish-catalogue/dish-catalogue.model'
import { FoodopLibModule } from '../../foodop-lib.module'
import { Tag } from '../tag/tag.model'
import { RecipesService } from './recipes.service'
import { distinctUntilChanged, startWith, tap } from 'rxjs/operators'
import { MenusService } from '../menu/menus.service'
import { TrackingsService } from '../tracking/trackings.service'
import { RecipePortion } from './recipe-portion.model'
import { RecipePortionIngredient } from '../raw-ingredient/recipe-portion-ingredient.model'
import { MenuDish } from '../menu-dish/menu-dish.model'
import * as uuid from 'uuid'
import { GlobalFunctionsService } from '../../services/global-functions.service'
import { RecipePortionSubRecipe } from './recipe-portion-sub-recipe.model'
import { ReturnStatement } from '@angular/compiler'
import { AllergensService } from '../allergen/allergens.service'
import { DishCatalogueService } from '../dish-catalogue/dish-catalogue.service'
import { NutritionFact } from '../nutrition/nutrition-fact.model'
import { SubsidiaryService } from '../subsidiary/subsidiary.service'
import { UserService } from '../user/user.service'
import { nutritionSorting } from '../../global.types'
import { IName, IRecipe, IRecipePortion } from '../../global.models'
import { Tracking } from '../tracking/tracking.model'
import { TagsService } from '../tag/tag.service'
import { RecipesScalingService } from './recipe-scaling.service'
import { NutritionService } from '../nutrition/nutrition.service'
import { ETagCategory } from '../tag/tag.service'

export class Recipe {
  id: string
  created_by: string
  organization_id: string
  subsidiary_id: string
  user_id: string

  names: Names
  search_name: string

  descriptions: Names
  production_description_steps: RecipeProductionStep[]
  allergens: Allergen[]
  tags: Tag[]

  total_ingredient_brutto_amount_in_kg: FormControl
  total_sub_recipe_brutto_amount_in_kg: FormControl

  nutrition: NutritionFact[]
  nutrition_base_unit: FormControl
  nutrition_base_preparation: FormControl
  nutrition_base_value: FormControl

  co2: FormControl

  popularity_stars: number // int [1:10]
  last_date: moment.Moment
  last_consumption: number
  last_food_waste: number
  last_portion_count: number

  lock: boolean
  default_shrinkage: number | string // For sub_recipes: Estimated shrinkage in % going fra uncleaned raw-ingredient to cleanded raw-ingredient
  shrinkage: FormControl // For sub_recipes: Estimated shrinkage in % going fra uncleaned raw-ingredient to cleanded raw-ingredient
  preparation_factor: FormControl // For sub_recipes: Estimated weight factor going from cleaned raw-ingredient to prepared ingredient
  netto_factor: FormControl // For sub_recipes: Combined factor going from uncleaned raw-ingredient to prepared ingredient
  estimated_share: number // For sub_recipes: Estimated share from db
  calculated_share: number // For sub_recipes: Actual share of total amount
  unit: FormControl // For sub_recipes: Unit of measurement

  accessibility: FormControl
  catalogue_ids: string[]
  user_favorite_ids: string[]

  ingredients: RawIngredient[]
  sub_recipes: Recipe[]
  portions: RecipePortion[]
  custom_portions: boolean
  selected_portion_id: FormControl
  is_selected: FormControl

  servings_locked = false
  portion_size_locked = true

  addingRecipeToMenu = false
  removingRecipeFromMenu = false
  saved_recipe: string

  existing_recipe_id: string
  previous_recipe_id: string

  loading_ingredient_suggestions: boolean = false
  loaded_ingredient_suggestions: boolean = false
  ingredient_suggestions_loaded: RawIngredient[] = []

  saving = false
  saved_with_empty_ingredients: boolean = false

  portion_selection_subscription: Subscription
  co2_subscription: Subscription

  portion_sizes_subscription: Subscription
  portion_servings_subscription: Subscription

  ingredient_share_subscription: Subscription
  ingredient_brutto_amount_subscription: Subscription
  sub_recipe_brutto_amount_subscription: Subscription
  ingredient_include_subscription: Subscription
  total_ingredient_brutto_amount_subscription: Subscription
  total_sub_recipe_brutto_amount_subscription: Subscription

  ingredient_shrinkage_subscription: Subscription
  ingredient_preparation_subscription: Subscription
  ingredient_netto_factor_subscription: Subscription
  ingredient_unit_subscription: Subscription
  sub_recipe_unit_subscription: Subscription

  servings_loaded: boolean = false

  allergensService: AllergensService
  nutritionService: NutritionService
  recipesServices: RecipesService
  menusService: MenusService
  trackingsService: TrackingsService
  subsidiaryService: SubsidiaryService
  userService: UserService
  dishCatalogueService: DishCatalogueService
  tagsService: TagsService
  RecipesScalingService: RecipesScalingService

  fb: FormBuilder
  func: GlobalFunctionsService

  constructor(public recipe?: IRecipe, public menu_dish?: MenuDish) {
    this.allergensService = FoodopLibModule.injector.get(AllergensService)
    this.nutritionService = FoodopLibModule.injector.get(NutritionService)
    this.recipesServices = FoodopLibModule.injector.get(RecipesService)
    this.menusService = FoodopLibModule.injector.get(MenusService)
    this.trackingsService = FoodopLibModule.injector.get(TrackingsService)
    this.subsidiaryService = FoodopLibModule.injector.get(SubsidiaryService)
    this.userService = FoodopLibModule.injector.get(UserService)
    this.dishCatalogueService = FoodopLibModule.injector.get(DishCatalogueService)
    this.tagsService = FoodopLibModule.injector.get(TagsService)
    this.RecipesScalingService = FoodopLibModule.injector.get(RecipesScalingService)

    this.fb = FoodopLibModule.injector.get(FormBuilder)
    this.func = FoodopLibModule.injector.get(GlobalFunctionsService)

    this.nutrition_base_unit = this.fb.control('GRM')
    this.nutrition_base_preparation = this.fb.control('UNPREPARED')
    this.nutrition_base_value = this.fb.control(100)

    this.id = recipe?.id
    this.created_by = this.isNewDish ? this.userService.user?.id?.value : recipe?.created_by
    this.names = new Names(recipe?.names)
    this.search_name = recipe?.search_name
    this.descriptions = new Names(recipe?.descriptions, 256)
    this.tags = (recipe?.tags || []).map((tag) => new Tag(tag)) as Tag[]
    this.production_description_steps = (recipe?.production_description_steps || []).sort((a, b) => (a.index < b.index ? -1 : 1)).map((production_description_step) => new RecipeProductionStep(production_description_step)) as RecipeProductionStep[]

    this.ingredients = (recipe?.ingredients || []).map((ingredient) => new RawIngredient(ingredient))
    this.sub_recipes = (recipe?.sub_recipes || []).map((sub_recipe) => new Recipe(sub_recipe))

    this.popularity_stars = recipe?.popularity_stars
    if (recipe?.last_date != undefined) this.last_date = moment(recipe.last_date)
    this.last_consumption = recipe?.last_consumption
    this.last_food_waste = recipe?.last_food_waste
    this.last_portion_count = recipe?.last_portion_count

    this.accessibility = this.fb.control(recipe?.accessibility || 0)
    this.organization_id = recipe?.organization_id
    this.subsidiary_id = recipe?.subsidiary_id
    this.user_id = recipe?.user_id

    this.catalogue_ids = recipe?.catalogue_ids || []
    this.user_favorite_ids = recipe?.user_favorite_ids || []

    this.total_ingredient_brutto_amount_in_kg = this.fb.control(0)
    this.total_sub_recipe_brutto_amount_in_kg = this.fb.control(0)

    this.custom_portions = recipe?.custom_portions || false
    this.setRecipePortions(recipe?.portions)
    this.selected_portion_id = this.fb.control(this.default_portion?.id)

    this.co2 = this.fb.control(this.calculateCO2())
    this.nutrition = this.calculateNutrition()

    const allergens: Allergen[] = (recipe?.allergens || []).map((allergen) => new Allergen(allergen)) as Allergen[]
    if (this.allergensService?.required_allergens?.length) {
      this.allergensService.required_allergens.forEach((allergen) => {
        if (!allergens.find((dish_allergen) => dish_allergen.code == allergen.code)) {
          allergens.push(new Allergen(allergen.as_dict))
        }
      })
    }
    this.allergens = allergens.sort((a, b) => (a.default_index > b.default_index ? 1 : -1))
    this._updateDishAllergenSuggestionsFromIngredients()

    this.default_shrinkage = recipe?.default_shrinkage || 1
    this.shrinkage = this.fb.control(recipe?.shrinkage?.toString() || '1')
    this.preparation_factor = this.fb.control(recipe?.preparation_factor?.toString() || '1')
    this.netto_factor = this.fb.control(recipe?.netto_factor?.toString() || '1')
    this.calculated_share = recipe?.calculated_share
    this.estimated_share = recipe?.estimated_share || 10
    this.lock = false
    this.unit = this.fb.control(recipe?.unit || 'kg')

    this.is_selected = this.fb.control(false)

    this._setMenuSectionTag()
    this.saveRecipeObject()
  }

  patchValue(recipe: IRecipe) {
    if (recipe.id) this.id = recipe.id
    if (recipe?.created_by) this.created_by = recipe.created_by
    if (recipe.names) this.names.patchValue(recipe.names)
    if (recipe.descriptions) this.descriptions.patchValue(recipe.descriptions)
    if (recipe.tags) this.tags = (recipe?.tags || []).map((tag) => new Tag(tag)) as Tag[]
    if (recipe.production_description_steps)
      this.production_description_steps = (recipe?.production_description_steps || []).sort((a, b) => (a.index < b.index ? -1 : 1)).map((production_description_step) => new RecipeProductionStep(production_description_step)) as RecipeProductionStep[]

    if (recipe.ingredients) this.ingredients = (recipe?.ingredients || []).map((ingredient) => new RawIngredient(ingredient))
    if (recipe.sub_recipes) this.sub_recipes = (recipe?.sub_recipes || []).map((sub_recipe) => new Recipe(sub_recipe))

    if (recipe.catalogue_ids) this.catalogue_ids = recipe?.catalogue_ids || []

    if (recipe.accessibility != undefined) this.accessibility.setValue(recipe.accessibility)
    if (recipe.organization_id) this.organization_id = recipe?.organization_id
    if (recipe.subsidiary_id) this.subsidiary_id = recipe?.subsidiary_id
    if (recipe.user_id) this.user_id = recipe?.user_id

    if (recipe?.popularity_stars != undefined) this.popularity_stars = recipe.popularity_stars
    if (recipe?.last_date != undefined) this.last_date = moment(recipe.last_date)
    if (recipe?.last_consumption != undefined) this.last_consumption = recipe.last_consumption
    if (recipe?.last_food_waste != undefined) this.last_food_waste = recipe.last_food_waste
    if (recipe?.last_portion_count != undefined) this.last_portion_count = recipe.last_portion_count

    if (recipe.custom_portions != undefined) this.custom_portions = recipe.custom_portions
    this.setRecipePortions(recipe?.portions)
    this.selected_portion_id.setValue(this.default_portion?.id)

    this.co2.setValue(this.calculateCO2())
    this.nutrition = this.calculateNutrition()

    if (recipe?.allergens) {
      const allergens: Allergen[] = (recipe?.allergens || []).map((allergen) => new Allergen(allergen)) as Allergen[]
      if (this.allergensService?.required_allergens?.length) {
        this.allergensService.required_allergens.forEach((allergen) => {
          if (!allergens.find((dish_allergen) => dish_allergen.code == allergen.code)) {
            allergens.push(new Allergen(allergen.as_dict))
          }
        })
      }
      this.allergens = allergens.sort((a, b) => (a.default_index > b.default_index ? 1 : -1))
      this._updateDishAllergenSuggestionsFromIngredients()
    }

    if (recipe?.default_shrinkage != undefined) this.default_shrinkage = recipe?.default_shrinkage || 0
    if (recipe?.calculated_share != undefined) this.calculated_share = recipe?.calculated_share
    if (recipe?.unit != undefined) this.unit.setValue(recipe?.unit)

    this.listenForPortionChanges()
    this.saveRecipeObject()
  }

  get isNewDish(): boolean {
    return this.id ? false : true
  }

  get created_by_me(): boolean {
    return this.userService.user?.id?.value == this.created_by
  }

  // Portion getters
  get default_portion(): RecipePortion {
    return this.portions.find((portion) => portion.default.value)
  }
  get portions_sorted(): RecipePortion[] {
    return this.portions.sort((a, b) => (a.index.value > b.index.value ? 1 : -1))
  }
  get sum_of_portion_servings(): number {
    return this.portions.map((menu_dish_portion) => this.func.roundString(menu_dish_portion.servings.value)).reduce((a, b) => a + b, 0)
  }

  // Amount getters //
  get sum_of_portion_ingredients_brutto_amounts_in_kg(): number {
    return this.func.round(
      this.ingredients.map((raw_ingredient) => this.sum_of_portion_brutto_amounts_for_sub_ingredient_in_kg(raw_ingredient.id)).reduce((a, b) => a + b, 0),
      2
    )
  }
  get sum_of_portion_ingredients_brutto_amounts_in_g(): number {
    return this.func.round(
      this.ingredients.map((raw_ingredient) => this.sum_of_portion_brutto_amounts_for_sub_ingredient_in_g(raw_ingredient.id)).reduce((a, b) => a + b, 0),
      2
    )
  }
  get sum_of_portions_netto_amounts_in_g(): number {
    return this.func.round(
      this.ingredients_and_sub_recipes.map((sub_ingredient) => this.sum_of_portion_brutto_amounts_for_sub_ingredient_in_g(sub_ingredient.id) * sub_ingredient.netto_factor.value).reduce((a, b) => a + b, 0),
      2
    )
  }

  get sum_of_portions_netto_amounts_in_kg(): number {
    return this.func.round(
      this.ingredients_and_sub_recipes.map((sub_ingredient) => this.sum_of_portion_brutto_amounts_for_sub_ingredient_in_kg(sub_ingredient.id) * sub_ingredient.netto_factor.value).reduce((a, b) => a + b, 0),
      2
    )
  }

  get sum_of_portion_ingredients_netto_amounts_in_kg(): number {
    return this.func.round(
      this.ingredients.map((raw_ingredient) => this.sum_of_portion_brutto_amounts_for_sub_ingredient_in_kg(raw_ingredient.id) * raw_ingredient.netto_factor.value).reduce((a, b) => a + b, 0),
      2
    )
  }
  get sum_of_portion_sub_recipes_netto_amounts_in_kg(): number {
    return this.func.round(
      this.sub_recipes.map((sub_recipe) => this.sum_of_portion_brutto_amounts_for_sub_ingredient_in_kg(sub_recipe.id) * sub_recipe.netto_factor.value).reduce((a, b) => a + b, 0),
      2
    )
  }
  get sum_of_portion_unlocked_sub_ingredient_shares(): number {
    return this.func.round(
      this.portions.map((portion) => portion.sum_of_unlocked_sub_ingredient_shares).reduce((a, b) => a + b, 0),
      2
    )
  }

  sum_of_portion_brutto_amounts_for_sub_ingredient_in_g(id: string): number {
    return this.portions.map((portion) => portion.portion_ingredient_or_sub_recipe_with_id(id)?.amount_in_kg * 1000 || 0).reduce((a, b) => a + b, 0)
  }

  sum_of_portion_brutto_amounts_for_sub_ingredient_in_kg(id: string): number {
    return this.portions.map((portion) => portion.portion_ingredient_or_sub_recipe_with_id(id)?.amount_in_kg || 0).reduce((a, b) => a + b, 0)
  }

  sum_of_portion_sub_recipes_brutto_amounts_for_sub_ingredient_in_kg(id: string): number {
    return this.sub_recipes
      .map((sub_recipe) => sub_recipe.sum_of_portion_brutto_amounts_for_sub_ingredient_in_kg(id))
      .flat()
      .reduce((a, b) => a + b, 0)
  }

  sum_of_portion_netto_amounts_for_sub_ingredient_in_kg(sub_ingredient_id: string): number {
    return this.portions
      .map((portion) => portion.portion_ingredient_or_sub_recipe_with_id(sub_ingredient_id)?.amount_in_kg * this.ingredients_and_sub_recipes.find((sub_ingredient) => sub_ingredient.id == sub_ingredient_id)?.netto_factor.value || 0)
      .reduce((a, b) => a + b, 0)
  }
  _sum_of_selected_portion_netto_amounts_for_sub_ingredient_in_kg(portions: RecipePortion[], sub_ingredient_id: string): number {
    return portions
      .map((portion) => portion.portion_ingredient_or_sub_recipe_with_id(sub_ingredient_id)?.amount_in_kg * this.ingredients_and_sub_recipes.find((sub_ingredient) => sub_ingredient.id == sub_ingredient_id)?.netto_factor.value || 0)
      .reduce((a, b) => a + b, 0)
  }
  _sum_of_selected_portion_netto_amounts_in_kg(portions: RecipePortion[]): number {
    return this.func.round(
      this.ingredients_and_sub_recipes.map((sub_ingredient) => this._sum_of_selected_portion_netto_amounts_for_sub_ingredient_in_kg(portions, sub_ingredient.id)).reduce((a, b) => a + b, 0),
      2
    )
  }
  is_calculated_shares_set_for_sub_ingredient(sub_ingredient_id: string): boolean {
    return this.portions.find((portion) => !portion.portion_ingredient_or_sub_recipe_with_id(sub_ingredient_id)?.calculated_share.value) == undefined
  }
  get total_brutto_amount_in_kg(): number {
    return (parseFloat(this.total_ingredient_brutto_amount_in_kg.value) || 0) + (parseFloat(this.total_sub_recipe_brutto_amount_in_kg.value) || 0)
  }
  get sum_of_portion_netto_amounts_based_on_serving_sizes(): number {
    return this.portions.map((recipe_portion) => recipe_portion.portion_netto_amount_based_on_serving_sizes || 0).reduce((a, b) => a + b, 0)
  }

  // ingredient getters
  get invalid_ingredients_and_sub_recipes(): Array<RecipePortionIngredient | RecipePortionSubRecipe> {
    return [].concat.apply(this.portions.map((portion) => portion.invalid_ingredients_and_sub_recipes)).flat()
  }
  get ingredients_sorted(): RawIngredient[] {
    return this.ingredients.sort((a, b) => {
      return this.default_portion?.portion_ingredient_with_id(a.id)?.index?.value > this.default_portion?.portion_ingredient_with_id(b.id)?.index?.value ? 1 : -1
    })
  }
  get sub_recipes_sorted(): Recipe[] {
    return this.sub_recipes.sort((a, b) => {
      return this.default_portion?.portion_sub_recipe_with_id(a.id)?.index?.value > this.default_portion?.portion_sub_recipe_with_id(b.id)?.index?.value ? 1 : -1
    })
  }
  get sub_recipes_with_production_descriptions_sorted(): Recipe[] {
    return this.sub_recipes_sorted.filter((sub_recipe) => sub_recipe.production_description_steps.length)
  }
  get sub_recipes_with_ingredients_sorted(): Recipe[] {
    return this.sub_recipes_sorted.filter((sub_recipe) => sub_recipe.ingredients.length)
  }
  get ingredients_and_sub_recipes(): Array<RawIngredient | Recipe> {
    return [...this.ingredients, ...this.sub_recipes]
  }
  get ingredients_and_sub_recipes_sorted(): Array<RawIngredient | Recipe> {
    return this.ingredients_and_sub_recipes.sort((a, b) => {
      return this.default_portion?.portion_ingredient_or_sub_recipe_with_id(a.id)?.index?.value > this.default_portion?.portion_ingredient_or_sub_recipe_with_id(b.id)?.index?.value ? 1 : -1
    })
  }
  get ingredients_with_brutto_amount_in_g(): Array<RawIngredient | Recipe> {
    return this.ingredients.filter((ingredient) => this.sum_of_portion_brutto_amounts_for_sub_ingredient_in_g(ingredient.id))
  }
  get sub_ingredients_with_amount_in_g(): Array<RawIngredient | Recipe> {
    return this.ingredients_and_sub_recipes.filter((sub_ingredient) => this.sum_of_portion_brutto_amounts_for_sub_ingredient_in_g(sub_ingredient.id))
  }
  get subIngredientsWithNutrition(): Array<RawIngredient | Recipe> {
    return this.ingredients_and_sub_recipes.filter((sub_ingredient) =>
      sub_ingredient.nutrition?.find(
        (nutrition) => nutrition.preparation.value == this.nutrition_base_preparation.value && nutrition.base_unit.value == this.nutrition_base_unit.value && nutrition.base_value.value == this.nutrition_base_value.value && nutrition.value.value != undefined
      )
    )
  }
  get are_total_portion_amounts_valid(): boolean {
    return this.portions.find((portion) => !portion.is_total_amount_valid) == undefined
  }

  // Nutrition getters
  get filtered_nutrition(): NutritionFact[] {
    return this.nutrition.filter(
      (nutrition_fact) =>
        nutrition_fact.preparation.value == this.nutrition_base_preparation.value &&
        nutrition_fact.base_unit.value == this.nutrition_base_unit.value &&
        nutrition_fact.base_value.value == this.nutrition_base_value.value &&
        ((nutrition_fact.nutrition_type_code_value == 'ENER-' && nutrition_fact.unit.value == 'E14') || 'ENER-' != nutrition_fact.nutrition_type_code_value)
    )
  }
  get sub_ingredients_without_nutrition(): Array<RawIngredient | Recipe> {
    return this.ingredients_and_sub_recipes.filter(
      (sub_ingredient) =>
        !sub_ingredient.nutrition?.find(
          (nutrition_fact) =>
            nutrition_fact.preparation.value == this.nutrition_base_preparation.value &&
            nutrition_fact.base_unit.value == this.nutrition_base_unit.value &&
            nutrition_fact.base_value.value == this.nutrition_base_value.value &&
            nutrition_fact.value.value != undefined &&
            ((nutrition_fact.nutrition_type_code_value == 'ENER-' && nutrition_fact.unit.value == 'E14') || 'ENER-' != nutrition_fact.nutrition_type_code_value)
        )
    )
  }
  get sub_ingredients_not_included_in_calculations(): Array<RawIngredient | Recipe> {
    return this.sub_ingredients_without_nutrition //.filter((sub_ingredient) => !sub_ingredient.include_in_calculations.value)
  }

  get production_steps_are_valid(): boolean {
    if (this.production_description_steps.find((step) => !step.valid)) return false
    else return true
  }

  ingredient_suggestions_filtered(language: string): RawIngredient[] {
    return this.ingredient_suggestions_loaded.filter((ingredient) => !this.ingredient_with_id(ingredient.id) && !this.ingredient_with_name(language, ingredient.names[language].value))
  }

  get added_empty_ingredients(): RawIngredient[] {
    return this.ingredients.filter((ingredient) => ingredient.ingredient_type == 'ai_generated' && ingredient.source?.data?.ingredient == null)
  }

  getIngredientSuggestions(language: string, name?: string): Observable<any> {
    if (name || this.names[language].value) {
      this.loading_ingredient_suggestions = true
      this.loaded_ingredient_suggestions = false
      return this.recipesServices.getIngredientSuggestions(name || this.names[language].value).pipe(tap(() => (this.loaded_ingredient_suggestions = true)))
    } else return of([])
  }

  loadDetailsForEmptyIngredients(language: string): void {
    let empty_ingredients = this.ingredient_suggestions_loaded.filter((ingredient) => ingredient.ingredient_type == 'ai_generated' && ingredient.source?.data?.ingredient == null)
    if (empty_ingredients.length) {
      this.recipesServices.getIngredientInfo(empty_ingredients).subscribe((ingredients) => {
        ;[...empty_ingredients, ...this.added_empty_ingredients].forEach((empty_ingredient) => {
          const matched_ingredient = ingredients.find((ingredient) => ingredient.names[language] == empty_ingredient.names[language].value)
          if (matched_ingredient) {
            empty_ingredient.patchValue(matched_ingredient)
          }
        })

        if (this.added_empty_ingredients.length) {
          this.co2.setValue(this.calculateCO2())
          this.nutrition = this.calculateNutrition()

          if (this.saved_with_empty_ingredients) {
            const checkRecipeSavingStatus = setInterval(() => {
              if (!this.saving) {
                clearInterval(checkRecipeSavingStatus)
                this.save().subscribe()
                if (this.menu_dish.id) this.menu_dish.save().subscribe()
                this.saved_with_empty_ingredients = false
              }
            }, 200)
          }
        }
      })
    }
  }

  addIngredientSuggestion(ingredient: RawIngredient): void {
    this.addIngredient(ingredient)
  }

  addAllIngredientSuggestions(language: string): void {
    this.ingredient_suggestions_filtered(language).forEach((ingredient) => {
      this.addIngredient(ingredient)
    })
  }

  get subsidiary_has_portion_templates(): boolean {
    return this.subsidiaryService.subsidiary?.portion_templates?.length ? true : false
  }

  setRecipePortions(portions: IRecipePortion[]): void {
    const has_portions: boolean = portions?.length ? true : false
    const has_custom_portions_set: boolean = this.custom_portions ? true : false
    const is_added_to_menu: boolean = this.menu_dish?.id ? true : false

    if (has_portions && has_custom_portions_set && (this.created_by_me || is_added_to_menu || !this.subsidiary_has_portion_templates)) {
      this._reindex_portions(portions || [])
      this.portions = (portions || []).map((portion) => new RecipePortion(this.ingredients, portion, this.sub_recipes))
    } else if (this.subsidiary_has_portion_templates) {
      this.portions = this.subsidiaryService.subsidiary.portion_templates.map(
        (portion_template) =>
          new RecipePortion(
            this.ingredients,
            portions?.find((portion) => portion.default),
            this.sub_recipes,
            null,
            portion_template
          )
      )
    } else if (has_portions) {
      const names: IName = {}
      names[this.func.language] = 'Standard portion'
      const new_recipe_portion = new RecipePortion(
        this.ingredients,
        portions?.find((portion) => portion.default),
        this.sub_recipes,
        100
      )
      new_recipe_portion.recipe_id = this.id
      new_recipe_portion.index.setValue(0)
      new_recipe_portion.default.setValue(true)
      new_recipe_portion.names.patchValue(names)
      this.portions = [new_recipe_portion]
    } else {
      this.portions = []
      const names: IName = {}
      names[this.func.language] = 'Standard portion'
      const new_recipe_portion = new RecipePortion(this.ingredients, null, this.sub_recipes, null)
      new_recipe_portion.recipe_id = this.id
      new_recipe_portion.index.setValue(this.portions.length)
      new_recipe_portion.default.setValue(this.portions.length ? false : true)
      new_recipe_portion.names.patchValue(names)
      this.ingredients_sorted.forEach((ingredient, index) => {
        const new_portion_ingredient: RecipePortionIngredient = new RecipePortionIngredient(ingredient, null, index)
        new_recipe_portion.portion_ingredients.push(new_portion_ingredient)
      })
      this.sub_recipes_sorted.forEach((recipe, index) => {
        const new_portion_sub_recipe: RecipePortionSubRecipe = new RecipePortionSubRecipe(null, recipe, index)
        new_recipe_portion.portion_sub_recipes.push(new_portion_sub_recipe)
      })
      this.portions.push(new_recipe_portion)
    }

    this.RecipesScalingService.updateTotalIngredientBruttoAmountBasedOnIngredientBruttoAmounts(this)
    this.RecipesScalingService.updateTotalSubRecipesBruttoAmountBasedOnSubRecipeBruttoAmounts(this)
    this.setSubRecipePortions()
  }

  _reindex_portions(portions): void {
    portions.forEach((portion, index) => {
      portion.index = index
    })
  }

  public addPortion(servings: number, names: IName): void {
    if (this.default_portion) {
      const new_recipe_portion_dict = this.default_portion?.as_dict
      const new_portion__id = uuid.v1()

      new_recipe_portion_dict.id = new_portion__id
      new_recipe_portion_dict.index = this.portions.length
      new_recipe_portion_dict.default = false
      const new_recipe_portion = new RecipePortion(this.ingredients, new_recipe_portion_dict, this.sub_recipes, servings)
      this.portions.push(new_recipe_portion)
    } else {
      const new_recipe_portion = new RecipePortion(this.ingredients, null, this.sub_recipes, servings)
      new_recipe_portion.recipe_id = this.id
      new_recipe_portion.index.setValue(this.portions.length)
      new_recipe_portion.default.setValue(this.portions.length ? false : true)
      new_recipe_portion.names.patchValue(names)
      this.ingredients_sorted.forEach((ingredient, index) => {
        const new_portion_ingredient: RecipePortionIngredient = new RecipePortionIngredient(ingredient, null, index)
        new_recipe_portion.portion_ingredients.push(new_portion_ingredient)
      })
      this.sub_recipes_sorted.forEach((recipe, index) => {
        const new_portion_sub_recipe: RecipePortionSubRecipe = new RecipePortionSubRecipe(null, recipe, index)
        new_recipe_portion.portion_sub_recipes.push(new_portion_sub_recipe)
      })
      this.portions.push(new_recipe_portion)
    }
    this.RecipesScalingService.updateTotalIngredientBruttoAmountBasedOnIngredientBruttoAmounts(this)
    this.RecipesScalingService.updateTotalSubRecipesBruttoAmountBasedOnSubRecipeBruttoAmounts(this)
    this.listenForPortionChanges()
    this.co2.setValue(this.calculateCO2())
    this.nutrition = this.calculateNutrition()
    this.custom_portions = true
  }

  public removePortion(recipe_portion: RecipePortion): void {
    const index_of_recipe_portion: number = this.portions.findIndex((portion) => portion.id == recipe_portion.id)
    if (index_of_recipe_portion >= 0) this.portions.splice(index_of_recipe_portion, 1)
    if (!recipe_portion.default.value) {
      const new_default_recipe = this.portions.find((portion) => portion.id != recipe_portion.id)
      new_default_recipe.default.setValue(true)
      recipe_portion.default.setValue(false)
    }
    this.selected_portion_id.setValue(this.default_portion.id)
    this.RecipesScalingService.updateTotalIngredientBruttoAmountBasedOnIngredientBruttoAmounts(this)
    this.RecipesScalingService.updateTotalSubRecipesBruttoAmountBasedOnSubRecipeBruttoAmounts(this)
    this.listenForPortionChanges()
    this.co2.setValue(this.calculateCO2())
    this.nutrition = this.calculateNutrition()
    this.custom_portions = true
  }

  public togglePortionDefault(recipe_portion: RecipePortion): void {
    if (recipe_portion.default.value) {
      const new_default_recipe = this.portions.find((portion) => portion.id != recipe_portion.id)
      new_default_recipe.default.setValue(true)
      recipe_portion.default.setValue(false)
    } else {
      const old_default_recipe = this.portions.find((portion) => portion.default.value)
      old_default_recipe.default.setValue(false)
      recipe_portion.default.setValue(true)
    }
    this.custom_portions = true
  }

  public listenForPortionNameChanges(language: string): void {
    merge(...this.portions.map((portion) => portion.names[language].valueChanges)).subscribe(() => {
      this.custom_portions = true
    })
  }

  public addIngredient(new_ingredient: RawIngredient): void {
    this._reindexPortionIngredients()
    this.ingredients.push(new_ingredient)
    this.portions.forEach((recipe_portion) => {
      const new_portion_ingredient: RecipePortionIngredient = new RecipePortionIngredient(new_ingredient, null, recipe_portion.portion_ingredients.length)
      recipe_portion.portion_ingredients.push(new_portion_ingredient)
      this._updateDishAllergenSuggestionsFromIngredients()
      this.updateDishAllergensFromIngredients(new_ingredient, null)
      this.listenForPortionChanges()
      this._updateTagsFromIngredients(new_ingredient)
      this.co2.setValue(this.calculateCO2())
      this.nutrition = this.calculateNutrition()
    })
  }

  public addSubRecipe(new_recipe: Recipe): void {
    this.sub_recipes.push(new_recipe)
    this.portions.forEach((recipe_portion) => {
      const new_portion_sub_recipe: RecipePortionSubRecipe = new RecipePortionSubRecipe(null, new_recipe, recipe_portion.portion_sub_recipes.length)
      recipe_portion.portion_sub_recipes.push(new_portion_sub_recipe)
      this.listenForPortionChanges()
      this._updateTagsFromIngredients(new_recipe)
      this.co2.setValue(this.calculateCO2())
      this.nutrition = this.calculateNutrition()
    })
  }

  public removeSubIngredient(sub_ingredient_to_remove: RawIngredient | Recipe): void {
    const index_of_ingredient: number = this.ingredients.findIndex((ingredient) => ingredient.id == sub_ingredient_to_remove.id)
    if (index_of_ingredient >= 0) {
      this.ingredients.splice(index_of_ingredient, 1)
    }
    const index_of_sub_recipe: number = this.sub_recipes.findIndex((sub_recipe) => sub_recipe.id == sub_ingredient_to_remove.id)
    if (index_of_sub_recipe >= 0) this.sub_recipes.splice(index_of_sub_recipe, 1)
    this.portions.forEach((portion) => {
      const index_of_ingredient: number = portion.portion_ingredients.findIndex((ingredient) => ingredient.object.id == sub_ingredient_to_remove.id)
      if (index_of_ingredient >= 0) portion.portion_ingredients.splice(index_of_ingredient, 1)
      const index_of_sub_recipe: number = portion.portion_sub_recipes.findIndex((sub_recipe) => sub_recipe.object.id == sub_ingredient_to_remove.id)
      if (index_of_sub_recipe >= 0) portion.portion_sub_recipes.splice(index_of_sub_recipe, 1)
    })
    this._reindexPortionIngredients()
    this._updateDishAllergenSuggestionsFromIngredients()
    this.updateDishAllergensFromIngredients(null, sub_ingredient_to_remove)
    this._updateTagsFromIngredients(null, sub_ingredient_to_remove)
    this.listenForPortionChanges()
    if (!this.portion_size_locked) this.RecipesScalingService.updatePortionSizesBasedOnNettoAmounts(this)
    else if (!this.servings_locked) {
      this.RecipesScalingService.updatePortionServingsBasedOnNettoAmounts(this)
      this.custom_portions = true
    }
    this.co2.setValue(this.calculateCO2())
    this.nutrition = this.calculateNutrition()
  }

  private _reindexPortionIngredients(): void {
    this.portions.forEach((recipe_portion) => {
      recipe_portion.portion_ingredients_sorted.forEach((sub_ingredient, index) => {
        sub_ingredient.index.setValue(index)
      })
    })
  }

  public reindexIngredients(): void {
    this.portions.forEach((recipe_portion) => {
      recipe_portion.portion_ingredients.forEach((sub_ingredient, index) => {
        sub_ingredient.index.setValue(index)
      })
    })
  }

  public listenForPortionChanges(): void {
    this.RecipesScalingService.listenForPortionScaling(this)
    this._listenForPortionSelectionChanges()
  }

  portion_with_id(portion_id: string): RecipePortion {
    return this.portions.find((portion) => portion.id == portion_id)
  }
  portion_with_template_id(template_id: string): RecipePortion {
    return this.portions.find((portion) => portion.portion_template_id == template_id)
  }
  portion_with_template_name(template_name: string, language: string): RecipePortion {
    return this.portions.find((portion) => portion.names[language].value == template_name)
  }
  ingredient_with_id(raw_ingredient_id: string): RawIngredient {
    return this.ingredients.find((raw_ingredient) => raw_ingredient.id == raw_ingredient_id)
  }
  ingredient_with_name(language: string, name: string): RawIngredient {
    return this.ingredients.find((raw_ingredient) => raw_ingredient.names[language].value == name)
  }
  replace_ingredient(raw_ingredient: RawIngredient, new_raw_ingredient: RawIngredient): void {
    const index = this.ingredients.findIndex((ingredient) => ingredient.id == raw_ingredient.id)
    if (index >= 0) {
      this.ingredients[index] = new_raw_ingredient
      this.portions.forEach((portion) => {
        const portion_ingredient_index = portion.portion_ingredients.findIndex((portion_ingredient) => portion_ingredient.object.id == raw_ingredient.id)
        if (portion_ingredient_index >= 0) portion.portion_ingredients[portion_ingredient_index].raw_ingredient = new_raw_ingredient
      })
    }
  }

  public saveRecipeObject() {
    this.saved_recipe = JSON.stringify(this.as_dict)
  }

  public checkIfUserHasRecipeWithName(name: string): Observable<any> {
    return this.recipesServices.getRecipeByName(name)
  }

  public loadExistingRecipe(menu_dish_id?: string): Observable<IRecipe> {
    return this.recipesServices.getRecipeByID(this.existing_recipe_id, menu_dish_id).pipe(
      tap((recipe) => {
        if (recipe) {
          this.previous_recipe_id = this.recipe?.id
          this.existing_recipe_id = null
          this.patchValue(recipe)
        }
      })
    )
  }

  get user_has_own_recipe(): boolean {
    return this.previous_recipe_id && this.previous_recipe_id != this.id
  }

  get sorted_facts(): NutritionFact[] {
    return this.nutrition
      .filter((nutrition_fact) => nutritionSorting[nutrition_fact.nutrition_type_code_value] > 0)
      .sort((a, b) => {
        return nutritionSorting[a.nutrition_type_code_value] > nutritionSorting[b.nutrition_type_code_value] ? 1 : -1
      })
  }

  _updateDishAllergenSuggestionsFromIngredients(): void {
    if (this.ingredients_and_sub_recipes?.length > 0) {
      const undeclared_or_not_included_allergens = this.allergens.filter((allergen) => allergen.containment.value == 'free_from' || !allergen.containment.value)
      undeclared_or_not_included_allergens.forEach((undeclared_or_not_included_allergen) => {
        const current_ingredients_contain_or_may_contain_allergen = this.ingredients_and_sub_recipes.find((sub_ingredient) =>
          sub_ingredient.allergens.find((allergen) => allergen.code == undeclared_or_not_included_allergen.code && (allergen.containment.value == 'contains' || allergen.containment.value == 'may_contained'))
        )

        if (current_ingredients_contain_or_may_contain_allergen) undeclared_or_not_included_allergen.containment.setValue('may_contained')
      })
    }
  }

  public updateDishAllergensFromIngredients(new_sub_ingredient: RawIngredient | Recipe, old_sub_ingredient: RawIngredient | Recipe): void {
    if (this.ingredients_and_sub_recipes?.length > 0) {
      this.allergens.forEach((recipe_allergen) => {
        const currently_contained: boolean = recipe_allergen.containment.value == 'contains'
        const currently_free_from: boolean = recipe_allergen.containment.value == 'free_from'

        const new_ingredient_allergen: Allergen = new_sub_ingredient?.allergens?.find((ingredient_allergen) => ingredient_allergen.code == recipe_allergen.code)
        const new_ingredient_contains_allergen: boolean = new_ingredient_allergen?.containment.value == 'contains'

        const old_ingredient_allergen: Allergen = old_sub_ingredient?.allergens?.find((allergen) => allergen.code == recipe_allergen.code)
        const old_ingredient_contains_allergen: boolean = old_ingredient_allergen?.containment.value == 'contains'
        const old_ingredient_may_contain_allergen: boolean = old_ingredient_allergen?.containment.value == 'may_contained'

        const current_ingredients_contains_allergen: boolean = this.ingredients.find((ingredient) => ingredient.allergens.find((ingredient_allergen) => ingredient_allergen.code == recipe_allergen.code && ingredient_allergen.containment.value == 'contains')) != undefined
        const current_ingredients_may_contain_allergen: boolean =
          this.ingredients.find((ingredient) => ingredient.allergens.find((ingredient_allergen) => ingredient_allergen.code == recipe_allergen.code && ingredient_allergen.containment.value == 'may_contained')) != undefined
        const current_ingredients_free_from_allergen: boolean =
          this.ingredients.find((ingredient) => ingredient.allergens.find((ingredient_allergen) => ingredient_allergen.code == recipe_allergen.code && ingredient_allergen.containment.value != 'free_from')) == undefined

        if (new_ingredient_contains_allergen) recipe_allergen.containment.setValue('contains')
        else if (!currently_contained && current_ingredients_free_from_allergen) recipe_allergen.containment.setValue('free_from')
        else if (!currently_contained && !current_ingredients_free_from_allergen) recipe_allergen.containment.setValue('undeclared')

        const old_ingredient_contained_allergen_as_the_only_ingredient = old_ingredient_contains_allergen && !current_ingredients_contains_allergen
        if (old_ingredient_contained_allergen_as_the_only_ingredient && current_ingredients_may_contain_allergen) recipe_allergen.containment.setValue('may_contained')
        if (old_ingredient_contained_allergen_as_the_only_ingredient && !current_ingredients_may_contain_allergen) recipe_allergen.containment.setValue('undeclared')

        const old_ingredient_may_contained_allergen_as_the_only_ingredient = old_ingredient_may_contain_allergen && !current_ingredients_contains_allergen && !current_ingredients_may_contain_allergen
        if (old_ingredient_may_contained_allergen_as_the_only_ingredient) recipe_allergen.containment.setValue('undeclared')
      })
    } else {
      this.allergens.forEach((recipe_allergen) => {
        const old_ingredient_allergen: Allergen = old_sub_ingredient?.allergens?.find((ingredient_allergen) => ingredient_allergen.code == recipe_allergen.code)
        const old_ingredient_contains_allergen: boolean = old_ingredient_allergen?.containment.value == 'contains'
        if (old_ingredient_contains_allergen) recipe_allergen.containment.setValue('undeclared')
      })
    }
  }

  get amount_locked_for_all_sub_ingredients() {
    return this.ingredients_and_sub_recipes.length ? (this.ingredients_and_sub_recipes.find((sub_ingredient) => !sub_ingredient.lock) ? false : true) : false
  }

  private _ingredientsString(language: string): string {
    if (this.ingredients_and_sub_recipes.length) {
      let ingredientString = ''
      this.ingredients_and_sub_recipes.forEach((sub_ingredient: RawIngredient | Recipe, index: number) => {
        if (this.ingredients_and_sub_recipes.length > 1 && index == this.ingredients_and_sub_recipes.length - 1) ingredientString += language == 'da' ? ' og ' : ' and '
        ingredientString += sub_ingredient.names[language].value
        if (this.ingredients_and_sub_recipes.length > 0 && index < this.ingredients_and_sub_recipes.length - 2) ingredientString += ', '
      })
      return ingredientString
    } else {
      return
    }
  }

  public ingredientsStringWithLabel(language: string): string {
    const ingredients_string: string = this._ingredientsString(language)
    if (ingredients_string) return language == 'da' ? 'Ingredienser: ' + ingredients_string : 'Ingredients: ' + ingredients_string
    else return
  }

  public get freeFromAllergens(): boolean {
    return this.allergens.filter((allergen: Allergen) => allergen.containment.value == 'free_from').length == this.allergensService.required_allergens.length
  }
  public allergensString(language: string, show_may_contain: boolean, dish_allergen_format: string, separator?: string): string {
    if (this.allergens.length) {
      if (this.freeFromAllergens) {
        return language == 'da' ? 'Fri for allergener' : 'Free from allergens'
      } else if (this.allergens.filter((allergen: Allergen) => allergen.containment.value == 'contains').length == 0 && (this.allergens.filter((allergen: Allergen) => allergen.containment.value == 'may_contain').length == 0 || !show_may_contain)) {
        ReturnStatement
      } else {
        const contains = this.allergens.filter((allergen: Allergen) => allergen.containment.value == 'contains')
        const may_contain = show_may_contain ? this.allergens.filter((allergen: Allergen) => allergen.containment.value == 'may_contain') : []

        let allergensString = ''
        contains.forEach((allergen: Allergen, index: number) => {
          if (contains.length > 1 && index == contains.length - 1) allergensString += separator || (language == 'da' ? ' og ' : ' and ')

          allergensString += allergen.names[language].value

          if (contains.length > 0 && index < contains.length - 2) allergensString += separator || ', '
        })

        may_contain.forEach((allergen: Allergen, index: number) => {
          if (contains.length == 0 && index == 0) allergensString += language == 'da' ? 'Spor af ' : 'Traces of '
          if (contains.length > 0 && index == 0) allergensString += language == 'da' ? ' samt spor af ' : ' and traces of '

          if (may_contain.length > 1 && index == may_contain.length - 1) allergensString += separator || (language == 'da' ? ' og ' : ' and ')

          allergensString += allergen.names[language].value

          if (may_contain.length > 0 && index < may_contain.length - 2) allergensString += separator || ', '
        })

        return allergensString
      }
    } else {
      return //language == 'da' ? 'Spørg kokken' : 'Ask the kitchen'
    }
  }

  public allergensStringWithLabel(language: string, show_may_contain: boolean, dish_allergen_format: string): string {
    const allergens_string: string = this.allergensString(language, show_may_contain, dish_allergen_format)
    if (allergens_string) return language == 'da' ? 'Allergener: ' + allergens_string : 'Allergens: ' + allergens_string
    else return
  }

  public co2StringWithLabel(language: string): string {
    let co2_level: string
    if (this.co2.value <= 2.3) co2_level = $localize`Lav`
    else if (this.co2.value > 2.3 && this.co2.value <= 10) co2_level = $localize`Middel`
    else co2_level = $localize`Høj`
    if (this.ingredients_and_sub_recipes.length) return (language == 'da' ? 'CO₂-aftryk (est.): ' : 'CO₂-footprint (est.): ') + co2_level + ' (' + this.co2.value.toPrecision(2) + ' kg/kg)'
    else return
  }

  get allergens_contained(): Allergen[] {
    return this.allergens.filter((allergen: Allergen) => allergen.containment.value == 'contains')
  }
  get allergens_may_contained(): Allergen[] {
    return this.allergens.filter((allergen: Allergen) => allergen.containment.value == 'may_contained')
  }
  get allergens_free_from(): Allergen[] {
    return this.allergens.filter((allergen: Allergen) => allergen.containment.value == 'free_from')
  }

  get lastServed() {
    if (!this.last_date) return 'other'
    let now = moment()
    var diff = moment.duration(now.diff(this.last_date))
    var days = Math.floor(diff.asDays())

    return days == 0 ? 'today' : days < 14 ? 'days' : days < 62 ? 'weeks' : days < 182 ? 'months' : this.last_date ? 'year' : 'other'
  }

  get timeSinceLastServed() {
    if (!this.last_date) return 'other'
    let now = moment(new Date())
    var diff = moment.duration(now.diff(this.last_date))
    var days = Math.floor(diff.asDays())

    return days > 14 && days < 62 ? Math.floor(days / 7) : days > 14 && days < 182 ? Math.floor(days / 30) : days
  }

  public ingredientsContainingAllergen(allergen: Allergen, language: string) {
    const ingredients_contains_allergen = this.ingredients_and_sub_recipes.filter((sub_ingredient) =>
      sub_ingredient.allergens.find((ingredient_allergen) => ingredient_allergen.code == allergen.code && (ingredient_allergen.containment.value == 'contains' || ingredient_allergen.containment.value == 'may_contained'))
    )

    if (ingredients_contains_allergen.length == 0) return null
    else {
      let reponse = ''
      ingredients_contains_allergen.forEach((ingredient, index) => {
        if (index > 0 && index < ingredients_contains_allergen.length - 1) reponse += ', '
        if (index > 0 && index == ingredients_contains_allergen.length - 1) reponse += language == 'da' ? ' og ' : ' and '
        reponse += ingredient.names[language].value
      })
      return reponse
    }
  }

  get changed(): boolean {
    return this.saved_recipe != JSON.stringify(this.as_dict)
  }

  get portions_changed(): boolean {
    return JSON.stringify(JSON.parse(this.saved_recipe)['portions']) != JSON.stringify(this.portions.map((portion) => portion.as_dict))
  }

  is_valid(language: string): boolean {
    return this.name_is_valid(language) && this.production_steps_are_valid
  }

  name_is_valid(language: string): boolean {
    return this.names[language]?.value?.length > 0
  }

  get full_stars(): Array<any> {
    return Array(Math.floor(this.popularity_stars / 2))
  }
  get half_stars(): Array<any> {
    return Array(Math.floor(this.popularity_stars % 2))
  }
  get empty_stars(): Array<any> {
    return Array(5 - Math.ceil(this.popularity_stars / 2))
  }

  public get usedTags(): Tag[] {
    return this.tags.filter((tag) => tag.id)
  }

  public hasTag(tag: Tag): boolean {
    return this.tags.find((added_tag) => added_tag.id == tag.id) != undefined
  }
  public addTag(tag: Tag): void {
    this.tags.push(tag)
  }
  public removeTag(tag: Tag): void {
    const index: number = this.tags.findIndex((added_tag) => added_tag.id == tag.id)
    if (index >= 0) this.tags.splice(index, 1)
  }

  public tagsForCategories(tagCategories: any[]): Tag[] {
    return this.tags.filter((tag) => tagCategories.map((tagCategory) => tagCategory.code).includes(tag.category))
  }

  public tagsLabel(language: string, separator?: string, lastSeparator?: string, tagCategories?: string[]): string {
    if (tagCategories?.length)
      return this.func.arrayToString(
        this.usedTags.filter((addedTag) => tagCategories.includes(addedTag.category)).map((tag) => tag.names[language].value),
        separator,
        lastSeparator
      )
    else
      this.func.arrayToString(
        this.usedTags.map((tag) => tag.names[language].value),
        separator,
        lastSeparator
      )
  }

  private _setMenuSectionTag(): void {
    if (this.isNewDish && this.tagsService.tagWithId(this.menu_dish?.menu_section?.section_template?.type?.value)) this.tags.push(this.tagsService.tagWithId(this.menu_dish.menu_section.section_template.type.value))
  }

  private _updateTagsFromIngredients(addedIngredient?: Recipe | RawIngredient, removedIngredient?: Recipe | RawIngredient): void {
    if (addedIngredient) this.tags.push(...this._tagsToPropagate(addedIngredient.usedTags).filter((tag) => !this.tags.find((existing_tag) => existing_tag.id == tag.id)))
    if (removedIngredient) {
      const tagsToRemove = this._tagsToPropagate(removedIngredient.usedTags).filter((removedIngredientTag) => !this.ingredients_and_sub_recipes.find((ingredient) => ingredient.tags.find((tag) => tag.id == removedIngredientTag.id)))
      this.tags = this.tags.filter((tag) => !tagsToRemove.find((tagToRemove) => tagToRemove.id == tag.id))
    }
  }

  private _tagsToPropagate(tags: Tag[]): Tag[] {
    return tags.filter((tag) => [ETagCategory.irritant, ETagCategory.meat_type].includes(tag.category))
  }

  public nutritionDisplayLabel(language: string, nutrition_types: string[]): string {
    if (this._selected_nutrition_types(nutrition_types).length)
      return (
        $localize`Per 100g (est.): ` +
        this.func.arrayToString(
          [].concat(this._selected_nutrition_types(nutrition_types)).map((nutrition_fact: NutritionFact) => nutrition_fact.names[language].value + ' ' + nutrition_fact.formatted_nutrition_value),
          ', ',
          $localize` og `
        )
      )
    else return ''
  }

  private _selected_nutrition_types(nutrition_types: string[]): NutritionFact[] {
    return this.nutrition.filter((nutrition_fact) => nutrition_types.includes(nutrition_fact.nutrition_type_code_value))
  }

  //  CATALOGUE MANAGEMENT //
  public toggleFavorite() {
    this.saving = true
    if (this.is_favorited) this.user_favorite_ids.splice(this.favorite_index, 1)
    else this.user_favorite_ids.push(this.userService.user?.id?.value)
    // Update db async:
    if (!this.is_favorited) return this.dishCatalogueService.removeDishFromMyDishes(this.as_dict)
    if (this.is_favorited) return this.dishCatalogueService.addDishToMyDishes(this.as_dict)
  }
  get is_favorited(): boolean {
    return this.favorite_index >= 0
  }
  get favorite_index(): number {
    return this.user_favorite_ids.findIndex((id) => id == this.userService.user?.id?.value)
  }

  public toggleDishInCatalogue(catalogue: DishCatalogue): Observable<any> {
    if (catalogue) {
      this.saving = true
      if (!this.isInCatalogue(catalogue)) {
        this.catalogue_ids.push(catalogue.id)
      } else {
        const index_of_catalogue: number = this.catalogue_ids.findIndex((catalogue_id) => catalogue_id == catalogue.id)
        if (index_of_catalogue >= 0) this.catalogue_ids.splice(index_of_catalogue, 1)
      }
      return this.dishCatalogueService.updateDishCatalogueAllocations(this)
    }
  }

  public isInCatalogue(catalogue: DishCatalogue): boolean {
    return this.catalogue_ids.find((catalogue_id) => catalogue_id == catalogue.id) != undefined
  }

  //  TRANSLATION  //
  public isNameTranslated(language: string) {
    return this.names[language].value ? true : false
  }

  public isDescriptionTranslated(language: string) {
    return this.descriptions[language].value ? true : false
  }

  get servings_for_menu_dish(): Tracking[] {
    return this.trackingsService.trackingsWithMenuDish(this.menu_dish.id)
  }

  translated_serving(language: string): Tracking {
    return this.servings_for_menu_dish.find((serving) => serving.language.value != language)
  }

  public updateServings() {
    this.servings_for_menu_dish.forEach((serving) => {
      serving.updateScaleDishDisplays().subscribe({
        complete() {
          serving.save().subscribe(() => console.log('Serving saved!'))
        }
      })
    })
  }

  public resetTranslations(): void {
    const da_name_has_changed: boolean = this.names.da.value && JSON.parse(this.saved_recipe).names.da && this.names.da.value != JSON.parse(this.saved_recipe).names.da
    const en_name_has_changed: boolean = this.names.en.value && JSON.parse(this.saved_recipe).names.en && this.names.en.value != JSON.parse(this.saved_recipe).names.en
    if (da_name_has_changed && this.names.en.value) this.names.en.setValue(null)
    if (en_name_has_changed && this.names.da.value) this.names.da.setValue(null)
  }

  private _listenForPortionSelectionChanges(): void {
    if (!this.portion_selection_subscription) {
      this.portion_selection_subscription = this.selected_portion_id.valueChanges.pipe(startWith(''), distinctUntilChanged()).subscribe((value) => {
        this.co2.setValue(this.calculateCO2())
        this.nutrition = this.calculateNutrition()
      })
    }
  }

  public toggleIngredientLocks(state?: boolean): void {
    if (state == undefined) state = !this.amount_locked_for_all_sub_ingredients
    this.ingredients_and_sub_recipes.forEach((sub_ingredient) => {
      sub_ingredient.lock = state
    })
    if (this.amount_locked_for_all_sub_ingredients) {
      this.servings_locked = false
      this.portion_size_locked = false
    }
  }
  toggleIngredientLock(ingredient): void {
    ingredient.lock = !ingredient.lock
    if (this.amount_locked_for_all_sub_ingredients) {
      this.servings_locked = false
      this.portion_size_locked = false
    }
  }
  toggleServingsLock(): void {
    this.servings_locked = !this.servings_locked
    if (this.servings_locked) this.portion_size_locked = false
    if (!this.servings_locked && !this.amount_locked_for_all_sub_ingredients) this.portion_size_locked = true
    if (this.amount_locked_for_all_sub_ingredients) this.toggleIngredientLocks(false)
  }
  togglePortionSizeLock(): void {
    this.portion_size_locked = !this.portion_size_locked
    if (this.portion_size_locked) this.servings_locked = false
    if (!this.portion_size_locked && !this.amount_locked_for_all_sub_ingredients) this.servings_locked = true
    if (this.amount_locked_for_all_sub_ingredients) this.toggleIngredientLocks(false)
  }

  updatePortionIDs(): void {
    this.portions.forEach((portion) => {
      portion.id = uuid.v1()
    })
  }

  save(): Observable<IRecipe> {
    this.saving = true
    if (this.added_empty_ingredients.length) this.saved_with_empty_ingredients = true
    this._reindexPortionIngredients()
    return this.recipesServices.upsertRecipe(this.as_dict).pipe(
      tap((response) => {
        if (response) {
          if (response['recipe_id']) {
            this.id = response['recipe_id']
          }
          this.saved_recipe = JSON.stringify(this.as_dict)
          this.saving = false
        }
      })
    )
  }

  // JSON objects:
  get as_dict(): IRecipe {
    return {
      id: this.id,
      names: this.names.as_dict,
      descriptions: this.descriptions.as_dict,
      production_description_steps: this.production_description_steps?.map((production_description_step) => production_description_step.descriptions_dict),
      ingredients: this.ingredients_sorted.map((ingredient) => ingredient.as_recipe_ingredient_dict),
      ingredient_count: this.ingredients.length,
      ingredient_with_amount_count: this.ingredients_with_brutto_amount_in_g.length,
      sub_recipes: this.sub_recipes.map((sub_recipe) => sub_recipe.as_dict),
      allergens: this.allergens?.map((allergen) => allergen.as_dict),
      popularity_stars: this.popularity_stars,
      last_date: this.last_date,
      last_consumption: this.last_consumption,
      last_food_waste: this.last_food_waste,
      last_portion_count: this.last_portion_count,
      tags: this.tags.map((tag) => tag.asDict),
      portions: this.portions_sorted.map((portion) => portion.as_dict),
      accessibility: this.accessibility.value,
      catalogue_ids: this.catalogue_ids.map((catalogue_id) => catalogue_id),
      user_favorite_ids: this.user_favorite_ids.map((user_favorite_id) => user_favorite_id),
      created_by: this.created_by,
      organization_id: this.organization_id,
      subsidiary_id: this.subsidiary_id,
      user_id: this.user_id,
      search_name: this.search_name,
      nutrition: this.sorted_facts.map((nutrition_fact) => nutrition_fact.as_dict),
      co2: this.calculateCO2(this.default_portion?.id),
      custom_portions: this.custom_portions,
      netto_factor: this.netto_factor.value,
      shrinkage: this.shrinkage.value,
      preparation_factor: this.preparation_factor.value,
      unit: this.unit.value
    }
  }

  setSubRecipePortions(): void {
    this.sub_recipes.forEach((sub_recipe) => {
      let sub_recipe_portions = []
      this.portions.forEach((recipe_portion) => {
        let recipe_portion_copy = recipe_portion.as_dict
        recipe_portion_copy.portion_ingredients = sub_recipe.default_portion.portion_ingredients.map((portion_ingredient) => portion_ingredient.as_dict)
        recipe_portion_copy.portion_sub_recipes = sub_recipe.default_portion.portion_sub_recipes.map((portion_sub_recipe) => portion_sub_recipe.as_dict)
        recipe_portion_copy.servings = recipe_portion.servings_long
        recipe_portion_copy.portion_size = (recipe_portion.portion_size_long * recipe_portion.portion_sub_recipe_with_id(sub_recipe.id).calculated_share.value) / 100
        let recipe_portion_copy_model = new RecipePortion(
          sub_recipe.ingredients.map((ingredient) => {
            ingredient.unit.setValue(sub_recipe.unit.value, { emitEvent: false })
            return ingredient
          }),
          recipe_portion_copy,
          sub_recipe.sub_recipes.map((sub_recipe) => {
            sub_recipe.unit.setValue(sub_recipe.unit.value, { emitEvent: false })
            return sub_recipe
          })
        )
        sub_recipe_portions.push(recipe_portion_copy_model)
      })
      sub_recipe.portions = sub_recipe_portions
      this.RecipesScalingService.updateTotalIngredientBruttoAmountBasedOnIngredientBruttoAmounts(this)
      this.RecipesScalingService.updateTotalSubRecipesBruttoAmountBasedOnSubRecipeBruttoAmounts(this)
    })
  }

  public updateRecipeCalculations(): void {
    this.co2.setValue(this.calculateCO2())
    this.nutrition = this.calculateNutrition()
  }

  public calculateCO2(selected_portion_id?: string): number {
    if (!selected_portion_id) selected_portion_id = this.selected_portion_id.value

    if (this.ingredients_and_sub_recipes.length) {
      let dish_co2 = 0

      let all_portion_ingredients_and_sub_recipes: Array<RecipePortionIngredient | RecipePortionSubRecipe> = []
      if (selected_portion_id && selected_portion_id != 'average') {
        all_portion_ingredients_and_sub_recipes = this.portion_with_id(selected_portion_id).portion_ingredients_and_sub_recipes
      } else all_portion_ingredients_and_sub_recipes = [].concat.apply(this.portions.map((portion) => portion.portion_ingredients_and_sub_recipes)).flat()

      const sum_of_share = all_portion_ingredients_and_sub_recipes.map((sub_ingredient) => (sub_ingredient.calculated_share.value != undefined ? sub_ingredient.calculated_share.value : sub_ingredient.object?.estimated_share)).reduce((a, b) => a + b, 0)
      all_portion_ingredients_and_sub_recipes.forEach((sub_ingredient) => {
        const share = (sub_ingredient.calculated_share.value != undefined ? sub_ingredient.calculated_share.value : sub_ingredient.object?.estimated_share) / sum_of_share // FIX: sub-recipes of sub-recipes are not loaded an hence, object will not be set
        dish_co2 += sub_ingredient.object?.co2?.value * share
      })

      return dish_co2
    } else {
      return null
    }
  }

  get selected_portions(): RecipePortion[] {
    if (this.selected_portion_id.value && this.selected_portion_id.value != 'average') return [this.portion_with_id(this.selected_portion_id.value)]
    else return this.portions
  }

  public calculateNutrition(portions?: RecipePortion[]): NutritionFact[] {
    let selected_portions: RecipePortion[] = portions
    if (!portions) selected_portions = this.selected_portions

    const ingredient_shares: number[] = this.subIngredientsWithNutrition.map((sub_ingredient) => {
      if (this._sum_of_selected_portion_netto_amounts_in_kg(selected_portions)) return this._sum_of_selected_portion_netto_amounts_for_sub_ingredient_in_kg(selected_portions, sub_ingredient.id) / this._sum_of_selected_portion_netto_amounts_in_kg(selected_portions)
      else return 1 / this.ingredients_and_sub_recipes.length
    })

    return this.nutritionService.aggregateNutritionFacts(
      this.subIngredientsWithNutrition.map((sub_ingredient) => sub_ingredient.filtered_nutrition),
      ingredient_shares
    )
  }
}
