import { HttpHeaders } from '@angular/common/http'
import { IDisplayElementTemplate, IMenuDish, IMenuSection, IMenuSectionTemplate, IMenuTemplate, IRecipe, IScale } from './global.models'
import { FormControl } from '@angular/forms'
import moment from 'moment'

export enum EBowlType {
  dish = 'dish',
  portion = 'portion',
  trash = 'trash'
}

export const unitList = [
  { name: { value: $localize`Kilo` }, id: 'kg' },
  { name: { value: $localize`Kilo CO₂` }, id: 'co2_kg' }
]

export const metricList = [
  { name: { value: $localize`Spist mængde` }, id: 'consumption' },
  { name: { value: $localize`Rester` }, id: 'food_waste' }
]

export const enum ELocationType {
  buffet = 'buffet',
  buffet_waste = 'buffet_waste',
  waste = 'waste'
}

export const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
}
export const required_nutrition_facts = [
  { nutrition_type_code_value: 'ENER-', names: { da: 'Energi', en: 'Energy' } },
  { nutrition_type_code_value: 'FAT', names: { da: 'Fedt', en: 'Fat' } },
  { nutrition_type_code_value: 'FASAT', names: { da: 'Heraf mættede fedtsyrer', en: 'Of which saturated fat' } },
  { nutrition_type_code_value: 'CHOAVL', names: { da: 'Kulhydrater', en: 'Carbohydrate' } },
  { nutrition_type_code_value: 'SUGAR-', names: { da: 'Heraf sukkerarter', en: 'Of which sugars' } },
  { nutrition_type_code_value: 'FIBTG', names: { da: 'Kostfibre', en: 'Fibre' } },
  { nutrition_type_code_value: 'PRO-', names: { da: 'Protein', en: 'Protein' } },
  { nutrition_type_code_value: 'SALTEQ', names: { da: 'Salt', en: 'Salt equivalents' } },
  { nutrition_type_code_value: 'ALC', names: { da: 'Alkohol', en: 'Alcohol' } },
  { nutrition_type_code_value: 'WATER', names: { da: 'Vand', en: 'Water' } }
]

export const procurement_data_columns = [
  { column: 'product_name', name: $localize`Varenavn`, category: $localize`Påkrævet`, required: true, format: 'string', default: true, overview_default: true, editable: false, bulk_editable: false, sortable: true },
  { column: 'amount', name: $localize`Vægt (kg)`, category: $localize`Påkrævet`, required: true, format: 'number', default: true, overview_default: true, editable: true, bulk_editable: false, sortable: true },
  { column: 'date', name: $localize`Dato`, category: $localize`Påkrævet`, required: false, format: 'date', default: true, overview_default: true, editable: true, bulk_editable: true, sortable: true, formControl: new FormControl() },
  { column: 'group_subsidiary_id', name: $localize`Køkken`, category: $localize`Gruppering`, required: false, format: 'select', default: false, overview_default: true, editable: true, bulk_editable: true, sortable: false, formControl: new FormControl() },
  { column: 'group_menu_template_id', name: $localize`Menu`, category: $localize`Gruppering`, required: false, format: 'select', default: false, overview_default: true, editable: true, bulk_editable: true, sortable: false, formControl: new FormControl() },
  { column: 'gtin', name: 'GTIN', category: $localize`Produktidentifikation`, required: false, format: 'string', default: false, overview_default: false, editable: true, bulk_editable: false, sortable: false },
  { column: 'ean', name: 'EAN', category: $localize`Produktidentifikation`, required: false, format: 'string', default: false, overview_default: false, editable: true, bulk_editable: false, sortable: false },
  { column: 'product_number', name: $localize`Produkt nummer`, category: $localize`Produktidentifikation`, required: false, format: 'string', default: false, overview_default: false, editable: false, bulk_editable: false, sortable: true },
  { column: 'supplier_product_number', name: $localize`Leverandør produktnummer`, category: $localize`Produktidentifikation`, required: false, format: 'string', default: false, overview_default: false, editable: true, bulk_editable: false, sortable: true },
  { column: 'organic', name: $localize`Økologi`, category: $localize`Produktinformation`, required: false, format: 'boolean', default: true, overview_default: true, editable: true, bulk_editable: true, sortable: true, formControl: new FormControl() },
  { column: 'origin_country', name: $localize`Oprindelsesland`, category: $localize`Produktinformation`, required: false, format: 'string', default: false, overview_default: false, editable: true, bulk_editable: true, sortable: false, formControl: new FormControl() },
  { column: 'origin_code', name: $localize`Oprindelseslandekode`, category: $localize`Produktinformation`, required: false, format: 'string', default: false, overview_default: false, editable: true, bulk_editable: true, sortable: false, formControl: new FormControl() },
  { column: 'gpc_code', name: $localize`GPC Kategorikode`, category: $localize`Produktinformation`, required: false, format: 'string', default: false, overview_default: false, editable: true, bulk_editable: true, sortable: true, formControl: new FormControl() },
  { column: 'gpc_name', name: $localize`GPC Kategorinavn`, category: $localize`Produktinformation`, required: false, format: 'string', default: false, overview_default: false, editable: true, bulk_editable: true, sortable: true, formControl: new FormControl() },
  { column: 'supplier', name: $localize`Leverandør`, category: $localize`Leverandørinformation`, required: false, format: 'string', default: false, overview_default: false, editable: true, bulk_editable: true, sortable: false, formControl: new FormControl() },
  { column: 'gln', name: $localize`Leverandør id (GLN)`, category: $localize`Leverandørinformation`, required: false, format: 'string', default: false, overview_default: false, editable: true, bulk_editable: true, sortable: true, formControl: new FormControl() },
  { column: 'manufacturer', name: $localize`Producentnavn`, category: $localize`Leverandørinformation`, required: false, format: 'string', default: false, overview_default: false, editable: true, bulk_editable: true, sortable: true, formControl: new FormControl() },
  { column: 'brand', name: $localize`Brandnavn`, category: $localize`Leverandørinformation`, required: false, format: 'string', default: false, overview_default: false, editable: true, bulk_editable: true, sortable: true, formControl: new FormControl() },
  { column: 'price', name: $localize`Pris (DKK/kg)`, category: $localize`Bestillingsinformation`, required: false, format: 'number', default: false, overview_default: true, editable: true, bulk_editable: true, sortable: true, formControl: new FormControl() },
  { column: 'total_price', name: $localize`Total pris (DKK)`, category: $localize`Bestillingsinformation`, required: false, format: 'number', default: false, overview_default: false, editable: true, bulk_editable: true, sortable: false, formControl: new FormControl() },
  { column: 'ingredient', name: $localize`Matched ingrediens`, required: false, format: 'input', default: false, overview_default: true, editable: true, bulk_editable: false, sortable: false },
  { column: 'co2', name: $localize`CO₂-aftryk (est. kg)`, required: false, format: 'nested_number', default: false, overview_default: true, editable: true, bulk_editable: false, sortable: false }
]

export const procurement_data_file_columns = [
  { column: 'file_name', name: $localize`Filnavn`, format: 'string', default: true },
  { column: 'created', name: $localize`Uploaded`, format: 'date', default: true },
  { column: 'updated', name: $localize`Sidst ændret`, format: 'date', default: true },
  { column: 'source', name: $localize`Leverandør`, format: 'string', default: false }
]

export const print_fonts = ['Arial', 'Verdana', 'Tahoma', 'Georgia', 'American Typewriter', 'Comic Sans MS', 'Calibri', 'Courier', 'Helvetica', 'Times', 'Lato', 'Roboto']

export const tag_categories = [
  { code: 'dietary', name: { value: $localize`Kosttyper` } },
  { code: 'irritant', name: { value: $localize`Irritanter` } },
  { code: 'meat_type', name: { value: $localize`Kødtyper` } },
  { code: 'season', name: { value: $localize`Sæsoner` } },
  { code: 'kitchen', name: { value: $localize`Køkkener` } },
  { code: 'section_type', name: { value: $localize`Rettyper` } }
]

export const read_accesses = [
  { code: 0, label: $localize`Kun dig` },
  { code: 1, label: $localize`Alle i mit køkken` },
  { code: 2, label: $localize`Alle i min organization` },
  { code: 3, label: $localize`Alle` }
]

export const write_accesses = [
  { code: 0, label: $localize`Kun dig` },
  { code: 1, label: $localize`Alle i mit køkken` },
  { code: 2, label: $localize`Alle i min organization` }
]

export const weekdays = [
  { da: 'Mandag', en: 'Monday' },
  { da: 'Tirsdag', en: 'Tuesday' },
  { da: 'Onsdag', en: 'Wednesday' },
  { da: 'Torsdag', en: 'Thursday' },
  { da: 'Fredag', en: 'Friday' },
  { da: 'Lørdag', en: 'Saturday' },
  { da: 'Søndag', en: 'Sunday' }
]

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL'
  },
  display: {
    dateInput: 'LL',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  }
}

export const nutritionSorting = {
  'ENER-': 1,
  FAT: 2,
  FASAT: 3,
  CHOAVL: 4,
  'SUGAR-': 5,
  'PRO-': 6,
  SALTEQ: 7,
  FIBTG: 8
}

export const macro_nutrition_facts = [
  { code: 'ENER-', name: { value: $localize`Energi` } },
  { code: 'FAT', name: { value: $localize`Fedt` } },
  { code: 'FASAT', name: { value: $localize`Umættet fedt` } },
  { code: 'CHOAVL', name: { value: $localize`Kulhydrater` } },
  { code: 'SUGAR-', name: { value: $localize`Sukker` } },
  { code: 'PRO-', name: { value: $localize`Protein` } },
  { code: 'SALTEQ', name: { value: $localize`Salt` } },
  { code: 'FIBTG', name: { value: $localize`Kostfibre` } }
]

export const enum EDisplayElementContentType {
  string = 'string',
  tags = 'tags',
  image = 'image'
}

export enum EDisplayElementInformationType {
  title = 'title',
  description = 'description',
  ingredients = 'ingredients',
  allergens = 'allergens',
  co2 = 'co2',
  tags = 'tags',
  nutrition = 'nutrition',
  logo = 'logo',
  section = 'section',
  scale_name = 'scale_name',
  scale_group = 'scale_group'
}

export const allScaleDisplayElements: IDisplayElementTemplate[] = [
  { code: EDisplayElementInformationType.title, height_weight: 5, max_lines: 3, min_lines: 1, row_index: 1, col_index: 1, min_font_size: 20, max_font_size: 50, font_family: 'Helvetica', text_alignment: 'left' },
  { code: EDisplayElementInformationType.description, height_weight: 4, max_lines: 3, min_lines: 1, row_index: 2, col_index: 1, min_font_size: 15, max_font_size: 25, font_family: 'Helvetica', text_alignment: 'left' },
  { code: EDisplayElementInformationType.ingredients, height_weight: 2, max_lines: 3, min_lines: 1, row_index: 3, col_index: 1, min_font_size: 15, max_font_size: 18, font_family: 'Helvetica', text_alignment: 'left' },
  { code: EDisplayElementInformationType.allergens, height_weight: 2, max_lines: 2, min_lines: 1, row_index: 4, col_index: 1, min_font_size: 15, max_font_size: 18, font_family: 'Helvetica', text_alignment: 'left' },
  { code: EDisplayElementInformationType.co2, height_weight: 1, max_lines: 1, min_lines: 1, row_index: 5, col_index: 1, min_font_size: 15, max_font_size: 18, font_family: 'Helvetica', text_alignment: 'left' },
  { code: EDisplayElementInformationType.tags, height_weight: 1, max_lines: 2, min_lines: 1, row_index: 6, col_index: 1, min_font_size: 15, max_font_size: 18, font_family: 'Helvetica', text_alignment: 'left' },
  { code: EDisplayElementInformationType.nutrition, height_weight: 1, max_lines: 2, min_lines: 1, row_index: 6, col_index: 1, min_font_size: 15, max_font_size: 18, font_family: 'Helvetica', text_alignment: 'left' },
  { code: EDisplayElementInformationType.scale_name, height_weight: 5, max_lines: 3, min_lines: 1, row_index: 1, col_index: 1, min_font_size: 20, max_font_size: 50, font_family: 'Helvetica', text_alignment: 'left' },
  { code: EDisplayElementInformationType.scale_group, height_weight: 2, max_lines: 2, min_lines: 1, row_index: 6, col_index: 1, min_font_size: 15, max_font_size: 25, font_family: 'Helvetica', text_alignment: 'left' }
]

export const defaultSelectedScaleDisplayElements: IDisplayElementTemplate[] = [
  { code: EDisplayElementInformationType.scale_name, height_weight: 5, max_lines: 3, min_lines: 1, row_index: 1, col_index: 1, min_font_size: 20, max_font_size: 50, font_family: 'Helvetica', text_alignment: 'left' },
  { code: EDisplayElementInformationType.scale_group, height_weight: 2, max_lines: 2, min_lines: 1, row_index: 6, col_index: 1, min_font_size: 15, max_font_size: 25, font_family: 'Helvetica', text_alignment: 'left' }
]

export const allDishDisplayElements: IDisplayElementTemplate[] = [
  { code: EDisplayElementInformationType.title, height_weight: 5, max_lines: 3, min_lines: 1, row_index: 1, col_index: 1, min_font_size: 20, max_font_size: 50, font_family: 'Helvetica', text_alignment: 'left' },
  { code: EDisplayElementInformationType.description, height_weight: 4, max_lines: 3, min_lines: 1, row_index: 2, col_index: 1, min_font_size: 15, max_font_size: 25, font_family: 'Helvetica', text_alignment: 'left' },
  { code: EDisplayElementInformationType.ingredients, height_weight: 2, max_lines: 3, min_lines: 1, row_index: 3, col_index: 1, min_font_size: 15, max_font_size: 18, font_family: 'Helvetica', text_alignment: 'left' },
  { code: EDisplayElementInformationType.allergens, height_weight: 2, max_lines: 2, min_lines: 1, row_index: 4, col_index: 1, min_font_size: 15, max_font_size: 18, font_family: 'Helvetica', text_alignment: 'left' },
  { code: EDisplayElementInformationType.co2, height_weight: 1, max_lines: 1, min_lines: 1, row_index: 5, col_index: 1, min_font_size: 15, max_font_size: 18, font_family: 'Helvetica', text_alignment: 'left' },
  { code: EDisplayElementInformationType.tags, height_weight: 1, max_lines: 2, min_lines: 1, row_index: 6, col_index: 1, min_font_size: 15, max_font_size: 18, font_family: 'Helvetica', text_alignment: 'left' },
  { code: EDisplayElementInformationType.nutrition, height_weight: 1, max_lines: 2, min_lines: 1, row_index: 6, col_index: 1, min_font_size: 15, max_font_size: 18, font_family: 'Helvetica', text_alignment: 'left' },
  { code: EDisplayElementInformationType.scale_name, height_weight: 5, max_lines: 3, min_lines: 1, row_index: 1, col_index: 1, min_font_size: 20, max_font_size: 50, font_family: 'Helvetica', text_alignment: 'left' },
  { code: EDisplayElementInformationType.scale_group, height_weight: 2, max_lines: 2, min_lines: 1, row_index: 6, col_index: 1, min_font_size: 15, max_font_size: 25, font_family: 'Helvetica', text_alignment: 'left' }
]

export const defaultSelectedDishDisplayElements: IDisplayElementTemplate[] = [
  { code: EDisplayElementInformationType.title, height_weight: 5, max_lines: 3, min_lines: 1, row_index: 1, col_index: 1, min_font_size: 20, max_font_size: 50, font_family: 'Helvetica', text_alignment: 'left' },
  { code: EDisplayElementInformationType.ingredients, height_weight: 2, max_lines: 3, min_lines: 1, row_index: 3, col_index: 1, min_font_size: 15, max_font_size: 18, font_family: 'Helvetica', text_alignment: 'left' },
  { code: EDisplayElementInformationType.allergens, height_weight: 2, max_lines: 2, min_lines: 1, row_index: 4, col_index: 1, min_font_size: 15, max_font_size: 18, font_family: 'Helvetica', text_alignment: 'left' }
]

export const enum EFunctionStatusCode {
  success = 'success',
  error = 'error',
  reachedMinFontSize = 'reachedMinFontSize',
  displayTemplateInvalid = 'displayTemplateInvalid',
  displayTemplateUpdated = 'displayTemplateUpdated'
}

export const localesList = [
  { code: 'da', label: $localize`Dansk` },
  { code: 'en', label: $localize`Engelsk` }
]

// Enumerators and types:
export declare const enum ECustomerType {
  canteen = 'canteen',
  catering = 'catering',
  caterer_customer = 'caterer_customer'
}
export declare const enum EUserRole {
  super_admin = 'super_admin',
  admin = 'admin',
  user = 'user',
  reader = 'reader',
  tester = 'tester'
}

export declare const enum EServingTemplateType {
  breakfast = 'breakfast',
  lunch = 'lunch',
  afternoon = 'afternoon',
  dinner = 'dinner',
  trash = 'trash'
}
export declare const enum ESectionType {
  warm_dishes = 'Varme retter',
  warm_dish_supplements = 'Varme retter - tilbehør',
  warm_dish_supplements_cold = 'Varme retter - koldt tilbehør',
  warm_dish_supplements_hot = 'Varme retter - varmt tilbehør',

  mixed_salads = 'mixed_salads',
  salad_components = 'salad_components',
  salad_supplements = 'salad_supplements',
  green_snacks = 'green_snacks',
  salad_dressings = 'salad_dressings',
  greens = 'greens',

  cold_cuts = 'cold_cuts',
  cold_cut_salads = 'cold_cut_salads',
  cold_cuts_supplements = 'cold_cuts_supplements',
  cold_cuts_dressings = 'cold_cuts_dressings',

  breads = 'breads',
  breads_white = 'breads_white',
  breads_dark = 'breads_dark',

  cakes = 'cakes',
  cake_supplements = 'cake_supplements',

  cheeses = 'cheeses',
  cheese_supplements = 'cheese_supplements',

  trash = 'trash'
}

export declare const enum EMenuComponentType {
  all = 'all',
  menu_title = 'menu_title',
  menu_date = 'menu_date',
  section_title = 'section_title',
  dish_title = 'dish_title',
  dish_description = 'dish_description',
  dish_ingredients = 'dish_ingredients',
  dish_allergens = 'dish_allergens',
  allergens_footnote = 'allergens_footnote',
  logo = 'logo'
}

export const menu_components = [
  //{'name':{'da':'Alle'},'type':EMenuComponentType.all},
  {
    name: { da: 'Titel', en: 'Title' },
    type: EMenuComponentType.menu_title
  },
  {
    name: { da: 'Dato', en: 'Date' },
    type: EMenuComponentType.menu_date
  },
  {
    name: { da: 'Sektionsoverskrifter', en: 'Section names' },
    type: EMenuComponentType.section_title
  },
  {
    name: { da: 'Retnavne', en: 'Dish names' },
    type: EMenuComponentType.dish_title
  },
  {
    name: { da: 'Retbeskrivelser', en: 'Dish descriptions' },
    type: EMenuComponentType.dish_description
  },
  {
    name: { da: 'Ingredienslister', en: 'Ingredients' },
    type: EMenuComponentType.dish_ingredients
  },
  {
    name: { da: 'Allergener', en: 'Allergens' },
    type: EMenuComponentType.dish_allergens
  },
  {
    name: { da: 'Fodnote', en: 'Footnote' },
    type: EMenuComponentType.allergens_footnote
  },
  { name: { da: 'Logo', en: 'Logo' }, type: EMenuComponentType.logo }
]

export declare const enum EDomains {
  front_page = 'front_page',
  serving_overview = 'serving_overview',
  reporting_view = 'reporting_view',
  settings = 'settings',
  settings_profile = 'settings_profile',
  log_out = 'log_out'
}

export declare const enum EScaleGroupTypes {
  dish = 'dish',
  trash = 'trash'
}

export interface IPushNotification {
  source: string
  type: ENotificationType
  success?: boolean
  'scale-dishes'?: any[]
  scales?: any[]
  data?: IGatewayNotification | IDataScaleDisplay | IDataScreenshot | IDataScan | IDataGetLogs | IDataScaleStatus | IDataGetStatusInfo | IDataDeleteData | IDataSetSampling | IDataGetStatus | IDataGetSystemInformation | IDataDownloadData | IDataGetTasks
}

export interface IGatewayNotification {
  gateway_id?: string
  status_code?: EGatewayStatusCode
  date?: string
  scale_dish?: {
    id: string
  }
  scales?: any[]
  type?: string
  source?: string
  serving_id?: string
}

export interface IDataScaleDisplay {
  scale: string
  dish: {
    name: string
    allergens: string
    extra: string
  }
}

export interface IDataScreenshot {
  screenshot: string
}

export interface IDataScan {
  peripherals: Array<IPeripheral>
}

export interface IPeripheral {
  type: string
  UUID: string
  name: string
  serial: string
  RSSI: number
}

export interface IDataGetLogs {
  logs: Array<string>
}

export interface IDataGatewayLogs {
  log: string
}

export interface IDataGetStatusInfo {
  status: Array<string>
}

export interface IDataSetSampling {
  scale: string
  sampling: boolean
}

export interface IDataScaleStatus {
  scale: IScale
  scale_index: number
  status?: IScaleStatus
}

export interface IDataGetStatus {
  scale: string
  status?: IScaleStatus
}

export interface IScaleStatus {
  sampling?: boolean
  display?: boolean
  charging?: boolean
  sleeping?: boolean
  samples?: number
  status: string
  data?: IScalePUTRequest
}

export interface IScalePUTRequest {
  sleeping?: boolean
  sampling?: boolean
  dish?: string
  extra?: string
  allergens?: string
  seen?: boolean
  day?: number
  status?: string
}
export interface IDataGetSystemInformation {
  scale: string
  information?: IScaleInformation
}

export interface IScaleInformation {
  firmware: string
  battery: number
}
export interface IDataDeleteData {
  scale: string
}

export interface IDataDownloadData {
  scale: string
}

export interface IDataNewMenu {
  date: string
}

export enum ETaskType {
  Display = 'Display',
  Start = 'Start',
  Stop = 'Stop',
  Download = 'Download',
  Delete = 'Delete',
  Status = 'Status',
  Information = 'Information',
  Sleep = 'Sleep',
  WakeUp = 'WakeUp',
  Scan = 'Scan',
  DisplayCheck = 'DisplayCheck',
  StartCheck = 'StartCheck',
  StartNowCheck = 'StartNowCheck',
  StopCheck = 'StopCheck',
  Connect = 'Connect',
  DisplayControllerUpdate = 'DisplayControllerUpdate'
}

export enum TaskStatus {
  task_started = 'task_started',
  task_succeeded = 'task_succeeded',
  task_attempt_failed = 'task_attempt_failed',
  task_failed = 'task_failed'
}

export interface ITask {
  type: ETaskType
  scale: string
  retry: number
  dish?: {
    id?: number
    name: string
    allergens: string
    extra: string
  }
  image?: number[]
  notify?: boolean
}

export interface ITaskQueue {
  Display: Array<ITask>
  Start: Array<ITask>
  Stop: Array<ITask>
  Download: Array<ITask>
  Other: Array<ITask>
}

export interface IDataGetTasks {
  tasks: ITaskQueue
  currentTask: ITask
}

export enum EGatewayStatusCode {
  serving_started,
  scale_started,
  serving_stopped,
  scale_stopped
}

export enum ENotificationType {
  Ping = 'ping',
  GatewayStatus = 'gateway-status',
  ClearGatewaySchedule = 'clear-schedule',
  UpdateGatewaySchedule = 'update-schedule',
  RecreateGatewayDB = 'remove-database',
  ForceStopScale = 'force-stop',
  NewScaleDisplay = 'new-scale-display',
  NewScaleDisplayDone = 'new-scale-display-done',
  TakeScreenshot = 'take-screenshot',
  TakeScreenshotDone = 'take-screenshot-done',
  Scan = 'scan',
  ScanDone = 'scan-done',
  GetLogs = 'get-logs',
  GetLogsDone = 'get-logs-done',
  GetGatewayLogs = 'get-log',
  GetStatusInfo = 'get-status-info',
  GetStatusInfoDone = 'get-status-info-done',
  GetScaleStatus = 'get-scale-status',
  GetScaleStatusDone = 'get-scale-status-done',
  DeleteData = 'delete-data',
  DeleteDataDone = 'delete-data-done',
  DownloadData = 'download-data',
  DownloadDataDone = 'download-data-done',
  SetSampling = 'set-sampling',
  SetSamplingDone = 'set-sampling-done',
  GetStatus = 'get-status',
  GetStatusDone = 'get-status-done',
  GetSystemInformation = 'get-system-information',
  GetSystemInformationDone = 'get-system-information-done',
  NewMenu = 'new-menu',
  NewMenuDone = 'new-menu-done',
  GetTasks = 'get-tasks',
  GetTasksDone = 'get-tasks-done',
  UpdateLocationMenu = 'update-locationmenu',
  RestartGateway = 'restart',
  UpdateGatewayFirmware = 'update-gateway-firmware',
  GetScaleData = 'get-scale-data',
  ReadScaleStatus = 'read-scale-status'
}

export interface INotificationPOSTRequest {
  destination: string
  notification: IPushNotification
}

export const sampleSectionTemplate1: IMenuSectionTemplate = {
  id: 'section_1',
  names: { da: 'Varme retter', en: 'Hot dishes' },
  index: 0
}

export const sampleSectionTemplate2: IMenuSectionTemplate = {
  id: 'section_2',
  names: { da: 'Salater', en: 'Salads' },
  index: 1
}

export const sampleSectionTemplate3: IMenuSectionTemplate = {
  id: 'section_3',
  names: { da: 'Pålæg', en: 'Cold cuts' },
  index: 2
}

export const sampleSectionTemplate4: IMenuSectionTemplate = {
  id: 'section_4',
  names: { da: 'Desserter', en: 'Desserts' },
  index: 3
}

export const menuTemplateTypes: any[] = [
  {
    default: false,
    sortOrder: 1,
    id: 'breakfast',
    da: 'Morgenmad',
    en: 'Breakfast'
  },
  {
    default: true,
    sortOrder: 2,
    id: 'lunch',
    da: 'Frokost',
    en: 'Lunch'
  },
  {
    default: false,
    sortOrder: 3,
    id: 'afternoon',
    da: 'Eftermiddagsmad',
    en: 'Afternoon snack'
  },
  {
    default: false,
    sortOrder: 4,
    id: 'dinner',
    da: 'Aftensmad',
    en: 'Dinner'
  }
]

export const sampleMenuTemplate1: IMenuTemplate = {
  id: 'sample_menu_template_1',
  names: { da: 'Frokost', en: 'Lunch' },
  menu_section_templates: [sampleSectionTemplate1, sampleSectionTemplate2, sampleSectionTemplate3, sampleSectionTemplate4]
}

export const sampleRecipe1: IRecipe = {
  accessibility: 0,
  production_description_steps: [],
  created_by: '5cc83e34-e80e-4453-ab6f-6031fbbfee9e',
  descriptions: {
    da: 'Italiensk pastaret med kød og tomater',
    en: 'Italian pasta dish with beef af tomatoes'
  },
  tags: [],
  allergens: [
    {
      containment: 'contains',
      names: {
        en: 'Sulphur Dioxide & Sulphites',
        da: 'Svovldioxid & sulfitter'
      },
      code: 'AU'
    },
    {
      containment: 'contains',
      names: {
        en: 'Celery',
        da: 'Selleri'
      },
      code: 'BC'
    },
    {
      containment: 'contains',
      names: {
        en: 'Tree nuts',
        da: 'Nødder'
      },
      code: 'AN'
    },
    {
      containment: 'contains',
      names: {
        en: 'Milk',
        da: 'Mælk'
      },
      code: 'AM'
    },
    {
      containment: 'contains',
      names: {
        en: 'Eggs',
        da: 'Æg'
      },
      code: 'AE'
    },
    {
      containment: 'contains',
      names: {
        en: 'Cereals containing gluten',
        da: 'Glutenholdigt korn'
      },
      code: 'AW'
    }
  ],
  ingredient_count: 0,
  names: {
    en: 'Lasagna',
    da: 'Lasagne'
  },
  catalogue_ids: [null],
  user_id: '5cc83e34-e80e-4453-ab6f-6031fbbfee9e',
  organization_id: '56075fbd-0fb1-11ec-8e99-c2e8e9002097',
  ingredients: [],
  sub_recipes: [],
  ingredient_with_amount_count: 0,
  id: '2d0302d5-f16a-11ec-a353-0242ac130003',
  search_name: 'Lasagne',
  subsidiary_id: '33d81125-19ec-11ec-adfe-4243a2e13677',
  user_favorite_ids: [null],
  portions: [
    {
      portion_sub_recipes: [],
      default: true,
      names: {
        da: 'Standard portion'
      },
      portion_size: 100,
      index: 0,
      id: '8976f0ec-8c3d-11ed-84f2-000d3abae7a7',
      portion_ingredients: []
    }
  ]
}

export const sampleRecipe2: IRecipe = {
  accessibility: 3,
  production_description_steps: [],
  created_by: '5cc83e34-e80e-4453-ab6f-6031fbbfee9e',
  descriptions: {
    da: 'Indisk kikærtegryde',
    en: 'Indian chickpea pot'
  },
  tags: [],
  allergens: [
    {
      containment: 'contains',
      names: {
        en: 'Sulphur Dioxide & Sulphites',
        da: 'Svovldioxid & sulfitter'
      },
      code: 'AU'
    },
    {
      containment: 'contains',
      names: {
        en: 'Celery',
        da: 'Selleri'
      },
      code: 'BC'
    }
  ],
  ingredient_count: 0,
  names: {
    en: 'Dhal with spinach and chickpeas',
    da: 'Dhal med spinat'
  },
  catalogue_ids: [null],
  user_id: '5cc83e34-e80e-4453-ab6f-6031fbbfee9e',
  organization_id: 'edd1b627-bd34-11eb-b9c7-0a216d9f6502',
  ingredients: [
    {
      co2: 1.001739130434783,
      ingredient_type: 'custom',
      allergens: [
        {
          containment: 'free_from',
          names: {
            en: 'Cereals containing gluten',
            da: 'Glutenholdigt korn'
          },
          code: 'AW'
        },
        {
          containment: 'free_from',
          names: {
            en: 'Milk',
            da: 'Mælk'
          },
          code: 'AM'
        },
        {
          containment: 'free_from',
          names: {
            en: 'Eggs',
            da: 'Æg'
          },
          code: 'AE'
        },
        {
          containment: 'free_from',
          names: {
            en: 'Peanuts',
            da: 'Jordnødder'
          },
          code: 'AP'
        },
        {
          containment: 'free_from',
          names: {
            en: 'Crustaceans',
            da: 'Krebsdyr'
          },
          code: 'AC'
        },
        {
          containment: 'free_from',
          names: {
            en: 'Fish',
            da: 'Fisk'
          },
          code: 'AF'
        },
        {
          containment: 'free_from',
          names: {
            en: 'Molluscs',
            da: 'Bløddyr'
          },
          code: 'UM'
        },
        {
          containment: 'free_from',
          names: {
            en: 'Lupine',
            da: 'Lupin'
          },
          code: 'NL'
        },
        {
          containment: 'free_from',
          names: {
            en: 'Sulphur Dioxide & Sulphites',
            da: 'Svovldioxid & sulfitter'
          },
          code: 'AU'
        },
        {
          containment: 'free_from',
          names: {
            en: 'Celery',
            da: 'Selleri'
          },
          code: 'BC'
        },
        {
          containment: 'free_from',
          names: {
            en: 'Mustard',
            da: 'Sennep'
          },
          code: 'BM'
        },
        {
          containment: 'free_from',
          names: {
            en: 'Soybeans',
            da: 'Soja'
          },
          code: 'AY'
        },
        {
          containment: 'free_from',
          names: {
            en: 'Tree nuts',
            da: 'Nødder'
          },
          code: 'AN'
        },
        {
          containment: 'free_from',
          names: {
            en: 'Sesame seeds',
            da: 'Sesamfrø'
          },
          code: 'AS'
        }
      ],
      preparation_factor: 1,
      unit: 'kg',
      nutrition: [
        {
          unit: 'E14',
          names: {
            en: 'Energy',
            da: 'Energi'
          },
          base_value: 100,
          avg_daily_intake_pct: null,
          precision: 'APPROXIMATELY',
          value: 127,
          nutrition_type_code_value: 'ENER-',
          base_unit: 'GRM',
          preparation: 'PREPARED'
        },
        {
          unit: 'KJO',
          names: {
            en: 'Energy',
            da: 'Energi'
          },
          base_value: 100,
          avg_daily_intake_pct: null,
          precision: 'APPROXIMATELY',
          value: 532,
          nutrition_type_code_value: 'ENER-',
          base_unit: 'GRM',
          preparation: 'PREPARED'
        },
        {
          unit: 'GRM',
          names: {
            en: 'Of which sugars',
            da: 'Heraf sukkerarter'
          },
          base_value: 100,
          avg_daily_intake_pct: null,
          precision: 'APPROXIMATELY',
          value: 11.4,
          nutrition_type_code_value: 'SUGAR-',
          base_unit: 'GRM',
          preparation: 'PREPARED'
        },
        {
          unit: 'GRM',
          names: {
            en: 'Protein',
            da: 'Protein'
          },
          base_value: 100,
          avg_daily_intake_pct: null,
          precision: 'APPROXIMATELY',
          value: 1.2,
          nutrition_type_code_value: 'PRO-',
          base_unit: 'GRM',
          preparation: 'PREPARED'
        },
        {
          unit: 'GRM',
          names: {
            en: 'Salt equivalent',
            da: 'Salt'
          },
          base_value: 100,
          avg_daily_intake_pct: null,
          precision: 'LESS_THAN',
          value: 0.1,
          nutrition_type_code_value: 'SALTEQ',
          base_unit: 'GRM',
          preparation: 'UNPREPARED'
        },
        {
          unit: 'GRM',
          names: {
            en: 'Of which saturated fat',
            da: 'Heraf mættede fedtsyrer'
          },
          base_value: 100,
          avg_daily_intake_pct: null,
          precision: 'APPROXIMATELY',
          value: 0.8,
          nutrition_type_code_value: 'FASAT',
          base_unit: 'GRM',
          preparation: 'PREPARED'
        },
        {
          unit: 'GRM',
          names: {
            en: 'Carbohydrate',
            da: 'Kulhydrater'
          },
          base_value: 100,
          avg_daily_intake_pct: null,
          precision: 'APPROXIMATELY',
          value: 11.962727272727276,
          nutrition_type_code_value: 'CHOAVL',
          base_unit: 'GRM',
          preparation: 'UNPREPARED'
        },
        {
          unit: 'GRM',
          names: {
            en: 'Protein',
            da: 'Protein'
          },
          base_value: 100,
          avg_daily_intake_pct: null,
          precision: 'APPROXIMATELY',
          value: 1.7000000000000002,
          nutrition_type_code_value: 'PRO-',
          base_unit: 'GRM',
          preparation: 'UNPREPARED'
        },
        {
          unit: 'GRM',
          names: {
            en: 'Fat',
            da: 'Fedt'
          },
          base_value: 100,
          avg_daily_intake_pct: null,
          precision: 'LESS_THAN',
          value: 0.5,
          nutrition_type_code_value: 'FAT',
          base_unit: 'GRM',
          preparation: 'UNPREPARED'
        },
        {
          unit: 'GRM',
          names: {
            en: 'Carbohydrate',
            da: 'Kulhydrater'
          },
          base_value: 100,
          avg_daily_intake_pct: null,
          precision: 'APPROXIMATELY',
          value: 13.4,
          nutrition_type_code_value: 'CHOAVL',
          base_unit: 'GRM',
          preparation: 'PREPARED'
        },
        {
          unit: 'GRM',
          names: {
            en: 'Of which saturated fat',
            da: 'Heraf mættede fedtsyrer'
          },
          base_value: 100,
          avg_daily_intake_pct: null,
          precision: 'APPROXIMATELY',
          value: 0.047099999999999996,
          nutrition_type_code_value: 'FASAT',
          base_unit: 'GRM',
          preparation: 'UNPREPARED'
        },
        {
          unit: 'GRM',
          names: {
            en: 'Salt equivalent',
            da: 'Salt'
          },
          base_value: 100,
          avg_daily_intake_pct: null,
          precision: 'APPROXIMATELY',
          value: 2.8,
          nutrition_type_code_value: 'SALTEQ',
          base_unit: 'GRM',
          preparation: 'PREPARED'
        },
        {
          unit: 'GRM',
          names: {
            en: 'Salt equivalent',
            da: 'Salt'
          },
          base_value: 100,
          avg_daily_intake_pct: null,
          precision: 'APPROXIMATELY',
          value: 0.00431578947368421,
          nutrition_type_code_value: 'SALTEQ',
          base_unit: 'GRM',
          preparation: 'UNPREPARED'
        },
        {
          unit: 'GRM',
          names: {
            en: 'Of which saturated fat',
            da: 'Heraf mættede fedtsyrer'
          },
          base_value: 100,
          avg_daily_intake_pct: null,
          precision: 'LESS_THAN',
          value: 0.5,
          nutrition_type_code_value: 'FASAT',
          base_unit: 'GRM',
          preparation: 'UNPREPARED'
        },
        {
          unit: 'E14',
          names: {
            en: 'Energy',
            da: 'Energi'
          },
          base_value: 100,
          avg_daily_intake_pct: null,
          precision: 'APPROXIMATELY',
          value: 60.09090909090909,
          nutrition_type_code_value: 'ENER-',
          base_unit: 'GRM',
          preparation: 'UNPREPARED'
        },
        {
          unit: 'GRM',
          names: {
            en: 'Fat',
            da: 'Fedt'
          },
          base_value: 100,
          avg_daily_intake_pct: null,
          precision: 'APPROXIMATELY',
          value: 0.19499999999999995,
          nutrition_type_code_value: 'FAT',
          base_unit: 'GRM',
          preparation: 'UNPREPARED'
        },
        {
          unit: 'GRM',
          names: {
            language: null
          },
          base_value: 100,
          avg_daily_intake_pct: null,
          precision: 'APPROXIMATELY',
          value: 1.9,
          nutrition_type_code_value: 'FIBTG',
          base_unit: 'GRM',
          preparation: 'PREPARED'
        },
        {
          unit: 'GRM',
          names: {
            en: 'Fat',
            da: 'Fedt'
          },
          base_value: 100,
          avg_daily_intake_pct: null,
          precision: 'APPROXIMATELY',
          value: 7.2,
          nutrition_type_code_value: 'FAT',
          base_unit: 'GRM',
          preparation: 'PREPARED'
        },
        {
          unit: 'KJO',
          names: {
            en: 'Energy',
            da: 'Energi'
          },
          base_value: 100,
          avg_daily_intake_pct: null,
          precision: 'APPROXIMATELY',
          value: 251.47727272727272,
          nutrition_type_code_value: 'ENER-',
          base_unit: 'GRM',
          preparation: 'UNPREPARED'
        },
        {
          unit: 'GRM',
          names: {
            en: 'Of which sugars',
            da: 'Heraf sukkerarter'
          },
          base_value: 100,
          avg_daily_intake_pct: null,
          precision: 'APPROXIMATELY',
          value: 5.030909090909091,
          nutrition_type_code_value: 'SUGAR-',
          base_unit: 'GRM',
          preparation: 'UNPREPARED'
        },
        {
          unit: 'GRM',
          names: {
            language: null
          },
          base_value: 100,
          avg_daily_intake_pct: null,
          precision: 'APPROXIMATELY',
          value: 2.8699999999999988,
          nutrition_type_code_value: 'FIBTG',
          base_unit: 'GRM',
          preparation: 'UNPREPARED'
        }
      ],
      names: {
        en: 'Onions',
        da: 'Løg'
      },
      organization_id: null,
      id: '793a4f6c-670e-11ec-9756-42d7d6440d72',
      subsidiary_id: null,
      shrinkage: 1
    }
  ],
  sub_recipes: [],
  ingredient_with_amount_count: 0,
  id: '9bef6f5f-3da9-11ed-82a6-0242ac130003',
  search_name: 'Dhal med spinat',
  subsidiary_id: '12037144-bd35-11eb-838a-0a216d9f6502',
  user_favorite_ids: [null],
  portions: [
    {
      portion_sub_recipes: [],
      default: true,
      names: {
        da: 'Standard portion'
      },
      portion_size: 100,
      index: 0,
      id: '89852aae-8c3d-11ed-b452-000d3abae7a7',
      portion_ingredients: [
        {
          ingredient_id: '793a4f6c-670e-11ec-9756-42d7d6440d72',
          calculated_share: 1,
          index: 0
        }
      ]
    }
  ]
}

export const sampleRecipe3: IRecipe = {
  accessibility: 0,
  production_description_steps: [],
  created_by: null,
  descriptions: {
    da: 'Klassisk skinkesalat med mayonaise',
    en: 'Classic ham salad'
  },
  tags: [],
  allergens: [],
  ingredient_count: 0,
  names: {
    da: 'Skinkesalat med urter',
    en: 'Ham salad with herbs'
  },
  catalogue_ids: [null],
  user_id: 'acdfeb1d-e287-4385-a686-d0afee965a70',
  organization_id: '56075fbd-0fb1-11ec-8e99-c2e8e9002097',
  ingredients: [],
  sub_recipes: [],
  ingredient_with_amount_count: 0,
  id: '882e1110-8d75-11ec-9ab5-8a09fac3823e',
  search_name: 'Skinkesalat med urter',
  subsidiary_id: '33d81125-19ec-11ec-adfe-4243a2e13677',
  user_favorite_ids: [null],
  portions: [
    {
      portion_sub_recipes: [],
      default: true,
      names: {
        da: 'Standard portion'
      },
      portion_size: 100,
      index: 0,
      id: '8980a538-8c3d-11ed-a579-000d3abae7a7',
      portion_ingredients: []
    }
  ]
}

export const sampleMenuDish1: IMenuDish = {
  index: 1,
  id: '1',
  subsidiary_id: null,
  menu_id: null,
  menu_section_id: null,
  recipe: sampleRecipe1
}

export const sampleMenuDish2: IMenuDish = {
  index: 2,
  id: '2',
  subsidiary_id: null,
  menu_id: null,
  menu_section_id: null,
  recipe: sampleRecipe2
}
export const sampleMenuDish3: IMenuDish = {
  index: 3,
  id: '3',
  subsidiary_id: null,
  menu_id: null,
  menu_section_id: null,
  recipe: sampleRecipe3
}

export const sampleMenuSection1: IMenuSection = {
  menu_section_template_id: 'section_1',
  section_index: 0,
  menu_dishes: [sampleMenuDish1, sampleMenuDish2]
}

export const sampleMenuSection2: IMenuSection = {
  menu_section_template_id: 'section_2',
  section_index: 1,
  menu_dishes: [sampleMenuDish3]
}

export const sampleMenu = {
  id: 'sample_menu_1',
  menu_tempalte_id: 'sample_menu_template_1',
  date: moment(),
  menu_sections: [sampleMenuSection1, sampleMenuSection2]
}
