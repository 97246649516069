import { PrintDocument } from '../print-document.model'
import { MenuPrintTemplateMenuElement } from '../../menu-print-template/elements/menu-element.model'
import { MenuPrintTemplateMenuDateElement } from '../../menu-print-template/elements/menu-date-element.model'
import { MenuPrintTemplateMenuTitleElement } from '../../menu-print-template/elements/menu-title-element.model'
import { MenuPrintTemplateMenuSectionElement } from '../../menu-print-template/elements/menu-section-element.model'
import { MenuPrintTemplateDishNameElement } from '../../menu-print-template/elements/dish-name-element.model'
import { MenuPrintTemplateDishDescriptionElement } from '../../menu-print-template/elements/dish-description-element.model'
import { MenuPrintTemplateDishIngredientsElement } from '../../menu-print-template/elements/dish-ingredients-element.model'
import { MenuPrintTemplateDishAllergensElement } from '../../menu-print-template/elements/dish-allergens-element.model'
import { MenuPrintTemplateDishTagsElement } from '../../menu-print-template/elements/dish-tags-element.model'
import { MenuPrintTemplateCustomTextElement } from '../../menu-print-template/elements/custom-text-element.model'
import { MenuPrintTemplateDishCO2Element } from '../../menu-print-template/elements/dish-co2-element.model'

export class PrintPageStringElement {
  constructor(
    public template:
      | MenuPrintTemplateMenuElement
      | MenuPrintTemplateMenuTitleElement
      | MenuPrintTemplateMenuDateElement
      | MenuPrintTemplateMenuSectionElement
      | MenuPrintTemplateDishNameElement
      | MenuPrintTemplateDishDescriptionElement
      | MenuPrintTemplateDishIngredientsElement
      | MenuPrintTemplateDishAllergensElement
      | MenuPrintTemplateDishTagsElement
      | MenuPrintTemplateDishCO2Element
      | MenuPrintTemplateCustomTextElement,
    public printDocument?: PrintDocument,
    public menuItemIdentifier?: string,
    public model?: any
  ) {}

  public get elementString(): string {
    if (this.template.type == 'image') return null
    else if (this.template.type == 'menuTitle') return (this.elementTemplate as MenuPrintTemplateMenuTitleElement).elementString(this.model, this.printDocument.menuPrintTemplate.language.value)
    else if (this.template.type == 'menuDate') return (this.elementTemplate as MenuPrintTemplateMenuDateElement).elementString(this.model, this.printDocument.menuPrintTemplate.language.value)
    else if (this.template.type == 'customString') return (this.elementTemplate as MenuPrintTemplateCustomTextElement).elementString(this.printDocument.menuPrintTemplate.language.value)
    else if (this.menuItemIdentifier == 'sectionName') return (this.elementTemplate as MenuPrintTemplateMenuSectionElement).elementString(this.model, this.printDocument.menuPrintTemplate.language.value)
    else if (this.menuItemIdentifier == 'dishName') return (this.elementTemplate as MenuPrintTemplateDishNameElement).elementString(this.model, this.printDocument.menuPrintTemplate.language.value)
    else if (this.menuItemIdentifier == 'dishDescription') return (this.elementTemplate as MenuPrintTemplateDishDescriptionElement).elementString(this.model, this.printDocument.menuPrintTemplate.language.value)
    else if (this.menuItemIdentifier == 'dishIngredients') return (this.elementTemplate as MenuPrintTemplateDishIngredientsElement).elementString(this.model, this.printDocument.menuPrintTemplate.language.value)
    else if (this.menuItemIdentifier == 'dishAllergens') return (this.elementTemplate as MenuPrintTemplateDishAllergensElement).elementString(this.model, this.printDocument.menuPrintTemplate.language.value)
    else if (this.menuItemIdentifier == 'dishTags') return (this.elementTemplate as MenuPrintTemplateDishTagsElement).elementString(this.model, this.printDocument.menuPrintTemplate.language.value)
    else if (this.menuItemIdentifier == 'dishCO2') return (this.elementTemplate as MenuPrintTemplateDishCO2Element).elementString(this.model, this.printDocument.menuPrintTemplate.language.value)
  }

  public get componentDOMSize(): number {
    if (!this.elementString) return 0
    const elem = document.createElement('div')
    elem.innerHTML = this.elementString
    elem.style.visibility = 'hidden'
    elem.style.position = 'absolute'
    elem.style.display = 'flex'
    elem.style.flexWrap = 'wrap'
    elem.style.width =
      this.printDocument.width *
        (1 - (this.printDocument.menuPrintTemplate.template.body.format.marginLeft.value * this.printDocument?.onePageScaling) / 210) *
        (1 - (this.printDocument.menuPrintTemplate.template.body.format.marginRight.value * this.printDocument?.onePageScaling) / 210) +
      'px'
    elem.style.font = this._font
    document.body.insertBefore(elem, document.body.firstChild)
    let height = elem.getBoundingClientRect().height
    if (this.menuItemIdentifier == 'dishCO2' && (this.elementTemplate as MenuPrintTemplateDishCO2Element).display.value == 'icon') {
      height = (this.printDocument.sizeFactor * 16 * 2.25 * this._fontSizeInPct) / 100
    }
    height += ((this.format.marginTop.value * this.printDocument?.onePageScaling) / 210) * this.printDocument?.width
    height += ((this.format.marginBottom.value * this.printDocument?.onePageScaling) / 210) * this.printDocument?.width
    document.body.removeChild(elem)
    return height
  }

  public get elementTemplate():
    | MenuPrintTemplateMenuTitleElement
    | MenuPrintTemplateMenuDateElement
    | MenuPrintTemplateMenuSectionElement
    | MenuPrintTemplateDishNameElement
    | MenuPrintTemplateDishDescriptionElement
    | MenuPrintTemplateDishIngredientsElement
    | MenuPrintTemplateDishAllergensElement
    | MenuPrintTemplateDishTagsElement
    | MenuPrintTemplateDishCO2Element
    | MenuPrintTemplateCustomTextElement {
    if (this.menuItemIdentifier)
      return (this.template as MenuPrintTemplateMenuElement).menuElements[this.menuItemIdentifier] as
        | MenuPrintTemplateMenuSectionElement
        | MenuPrintTemplateDishNameElement
        | MenuPrintTemplateDishDescriptionElement
        | MenuPrintTemplateDishIngredientsElement
        | MenuPrintTemplateDishAllergensElement
        | MenuPrintTemplateDishTagsElement
        | MenuPrintTemplateDishCO2Element
    return this.template as MenuPrintTemplateMenuTitleElement | MenuPrintTemplateMenuDateElement | MenuPrintTemplateCustomTextElement
  }
  public get format(): any {
    return this.elementTemplate.format
  }

  private get _font(): string {
    return (this.format.fontStyle.value.includes('bold') ? 'bold' : '') + ' ' + (this.format.fontStyle.value.includes('italic') ? 'italic' : '') + ' ' + this._pctToPx(this._fontSizeInPct) + 'px ' + this.format.fontFamily.value
  }

  private get _fontSizeInPct(): number {
    return this.format.fontSize.value * this.printDocument?.onePageScaling * 8.415
  }

  private _pctToPx(percentage: number): number {
    return (percentage / 100) * this.printDocument?.sizeFactor * 16
  }

  public get cloudPosition(): object {
    switch (this.format.textAlignment.value) {
      case 'left':
        return {
          'justify-content': 'flex-start'
        }
      case 'right':
        return {
          'justify-content': 'flex-end'
        }
      case 'center':
        return {
          'justify-content': 'center'
        }
    }
  }

  public get CO2IconStyle(): any {
    return {
      width: this._fontSizeInPct * 2.25 + '%',
      height: '100%',
      'font-size': '225%'
    }
  }

  public get CO2LabelStyle(): any {
    return {
      'line-height': '100%',
      'margin-left': '-25%',
      padding: '5% 10% 5% 30%',
      height: ' 65%',
      'border-radius': '7px'
    }
  }

  public get style(): any {
    return Object.assign(
      {
        'line-height': '115%',
        'font-size': this._fontSizeInPct + '%',
        'font-family': this.format.fontFamily.value,
        'font-weight': this.format.fontStyle.value.includes('bold') ? 'bold' : 'normal',
        'font-style': this.format.fontStyle.value.includes('italic') ? 'italic' : 'normal',
        'text-decoration': this.format.fontStyle.value.includes('underline') ? 'underline' : 'none',
        'text-align': this.format.textAlignment.value,
        color: this.format.fontColor.value
      },
      this.margins
    )
  }

  public get margins(): any {
    return {
      'margin-top': ((this.format.marginTop.value * this.printDocument?.onePageScaling) / 210) * 100 + '%',
      'margin-right': ((this.format.marginRight.value * this.printDocument?.onePageScaling) / 210) * 100 + '%',
      'margin-bottom': ((this.format.marginBottom.value * this.printDocument?.onePageScaling) / 210) * 100 + '%',
      'margin-left': ((this.format.marginLeft.value * this.printDocument?.onePageScaling) / 210) * 100 + '%'
    }
  }
}
